const LocationReviewsData = [
    {
        "date": "2024-02-15",
        "review_rate": 4.8,
        "customer_name": "Richard Vazquez",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Emily M.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-06-26",
        "review_rate": 5.0,
        "customer_name": "Bradley Davis",
        "service_type": "Garage Door Services",
        "tech_name": "George R.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-06-24",
        "review_rate": 5.0,
        "customer_name": "Dawn Deleon",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Jacqueline V.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-02-15",
        "review_rate": 4.8,
        "customer_name": "Kevin Scott",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Shelby P.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-02-25",
        "review_rate": 4.8,
        "customer_name": "Janice Meadows",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Mariah C.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-02-27",
        "review_rate": 4.9,
        "customer_name": "Dawn Sims",
        "service_type": "Garage Door Insulation",
        "tech_name": "Victoria C.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-12-18",
        "review_rate": 4.9,
        "customer_name": "Michael Porter",
        "service_type": "Garage Door Installation",
        "tech_name": "Karl M.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-10-08",
        "review_rate": 4.9,
        "customer_name": "Hayley Burnett DVM",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Anthony G.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-09-21",
        "review_rate": 4.9,
        "customer_name": "Antonio Pope",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Kyle B.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-28",
        "review_rate": 5.0,
        "customer_name": "Ashlee Brooks",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Jenny S.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-01-26",
        "review_rate": 4.9,
        "customer_name": "Lisa Ray",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "David I.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-18",
        "review_rate": 4.8,
        "customer_name": "Adam Lawson",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Morgan T.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-02-13",
        "review_rate": 5.0,
        "customer_name": "Rachel Johnson",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Gabriela N.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-09-14",
        "review_rate": 4.9,
        "customer_name": "Megan Morris",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Jeremy K.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-01-07",
        "review_rate": 5.0,
        "customer_name": "Linda Griffin",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Matthew B.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-21",
        "review_rate": 4.9,
        "customer_name": "Laura Wheeler",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Angela J.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-01-29",
        "review_rate": 4.9,
        "customer_name": "David Murray",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Erika H.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-06-21",
        "review_rate": 5.0,
        "customer_name": "Michelle Kemp",
        "service_type": "Garage Door Installation",
        "tech_name": "John T.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-01-23",
        "review_rate": 4.9,
        "customer_name": "Tina Henderson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Carlos S.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-08",
        "review_rate": 4.9,
        "customer_name": "Danielle Kelley",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Nathan A.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-11-26",
        "review_rate": 5.0,
        "customer_name": "Lisa Hubbard",
        "service_type": "Garage Door Insulation",
        "tech_name": "Carol R.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-01-06",
        "review_rate": 5.0,
        "customer_name": "Daniel Hebert",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Eric H.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-30",
        "review_rate": 4.8,
        "customer_name": "Melissa Henry",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Jonathan J.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-02-25",
        "review_rate": 5.0,
        "customer_name": "Andrea Munoz",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Tiffany R.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-08-06",
        "review_rate": 4.9,
        "customer_name": "Johnny Bush",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Christine B.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-01-19",
        "review_rate": 4.9,
        "customer_name": "Susan Cordova",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Patricia W.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-06",
        "review_rate": 4.9,
        "customer_name": "Michelle Dawson",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Cynthia N.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-14",
        "review_rate": 4.9,
        "customer_name": "Heather Moore",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Kristen P.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-01-28",
        "review_rate": 5.0,
        "customer_name": "Jessica Hubbard",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Cassandra K.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-25",
        "review_rate": 4.9,
        "customer_name": "Ryan Duncan",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Caitlin M.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-10-02",
        "review_rate": 5.0,
        "customer_name": "Glen Welch",
        "service_type": "Garage Door Services",
        "tech_name": "Ricardo M.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-09-15",
        "review_rate": 4.8,
        "customer_name": "Kristen Rodriguez",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Belinda T.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-03-15",
        "review_rate": 5.0,
        "customer_name": "Veronica Walton",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Jamie M.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-04-23",
        "review_rate": 4.9,
        "customer_name": "Jeffrey James",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Benjamin J.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-10-01",
        "review_rate": 4.9,
        "customer_name": "James Mcdaniel",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Bob H.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-03-14",
        "review_rate": 4.9,
        "customer_name": "Shannon Ramos",
        "service_type": "Garage Door Services",
        "tech_name": "Alexander B.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-03-01",
        "review_rate": 5.0,
        "customer_name": "Laura Johnson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Ryan B.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-02-05",
        "review_rate": 4.8,
        "customer_name": "Michele Buck",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "John B.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-11-07",
        "review_rate": 5.0,
        "customer_name": "Amber Rodriguez",
        "service_type": "Garage Door Opener",
        "tech_name": "Nicole L.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-20",
        "review_rate": 4.9,
        "customer_name": "Deanna Reyes",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Jordan J.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-11-02",
        "review_rate": 4.8,
        "customer_name": "Kenneth Estrada",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Mary G.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-09",
        "review_rate": 4.9,
        "customer_name": "Thomas Reeves",
        "service_type": "Garage Door Repair",
        "tech_name": "Priscilla P.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-09-28",
        "review_rate": 4.8,
        "customer_name": "Jason Ward",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Sheila Y.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-29",
        "review_rate": 4.8,
        "customer_name": "Troy Steele",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Dave J.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-01-24",
        "review_rate": 5.0,
        "customer_name": "Joshua Cook",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Ray B.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-01-27",
        "review_rate": 5.0,
        "customer_name": "Samuel Warren",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Andrew C.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-06-03",
        "review_rate": 4.8,
        "customer_name": "James Fisher",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Mark C.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-04-12",
        "review_rate": 4.9,
        "customer_name": "Brian Smith",
        "service_type": "Garage Door Installation",
        "tech_name": "Ashlee V.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-18",
        "review_rate": 4.8,
        "customer_name": "Joseph Delacruz",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Benjamin G.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-12-20",
        "review_rate": 5.0,
        "customer_name": "Kelly Stewart",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Samuel B.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-19",
        "review_rate": 4.9,
        "customer_name": "Yesenia Robinson",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Hector F.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-06-18",
        "review_rate": 5.0,
        "customer_name": "Karina Hernandez",
        "service_type": "Garage Door Off Track",
        "tech_name": "Andrew V.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-02-22",
        "review_rate": 5.0,
        "customer_name": "Tina Austin",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Jay Y.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-04-29",
        "review_rate": 4.9,
        "customer_name": "Michael Clarke",
        "service_type": "Garage Door Repair",
        "tech_name": "Frank M.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-01-23",
        "review_rate": 5.0,
        "customer_name": "Derrick Eaton",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Cindy S.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-11-15",
        "review_rate": 5.0,
        "customer_name": "Joshua Davis",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Tracy B.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-01-04",
        "review_rate": 4.9,
        "customer_name": "Blake Taylor",
        "service_type": "Garage Door Insulation",
        "tech_name": "Jennifer B.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-09-28",
        "review_rate": 5.0,
        "customer_name": "John Cantu",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Lori J.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-11-19",
        "review_rate": 4.9,
        "customer_name": "Charles Pittman",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Samuel C.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-10-08",
        "review_rate": 5.0,
        "customer_name": "Becky Collins",
        "service_type": "Garage Door Repair",
        "tech_name": "Jennifer B.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-09-17",
        "review_rate": 5.0,
        "customer_name": "Joseph Martinez",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Douglas C.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-02-05",
        "review_rate": 4.9,
        "customer_name": "Phillip Thompson",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Matthew R.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-05-21",
        "review_rate": 4.8,
        "customer_name": "Mary Pearson",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Annette F.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-07-23",
        "review_rate": 4.8,
        "customer_name": "Jason Zamora",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "William D.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-07-02",
        "review_rate": 4.9,
        "customer_name": "Kenneth Matthews",
        "service_type": "Garage Door Off Track",
        "tech_name": "Brandon R.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-06-09",
        "review_rate": 5.0,
        "customer_name": "Claudia Nguyen",
        "service_type": "Garage Door Repair",
        "tech_name": "Tyler S.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-08-15",
        "review_rate": 4.9,
        "customer_name": "Morgan Johnson",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Maria L.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-05-09",
        "review_rate": 4.8,
        "customer_name": "Alexandria Reilly",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "James B.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-29",
        "review_rate": 4.8,
        "customer_name": "Alexander Kane",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "David A.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-03-12",
        "review_rate": 4.9,
        "customer_name": "Corey Evans",
        "service_type": "Garage Door Services",
        "tech_name": "Dylan P.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-06",
        "review_rate": 4.8,
        "customer_name": "Jessica Thompson",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Christopher G.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-08-10",
        "review_rate": 4.9,
        "customer_name": "Kari Williams",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "James M.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-03-22",
        "review_rate": 5.0,
        "customer_name": "Deborah Rodriguez",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Debra P.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-03-12",
        "review_rate": 4.9,
        "customer_name": "Michelle Ingram",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Crystal S.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-06-27",
        "review_rate": 5.0,
        "customer_name": "Maria White",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Patrick W.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-06-29",
        "review_rate": 4.9,
        "customer_name": "Matthew Dunn",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Ryan J.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-10-20",
        "review_rate": 4.9,
        "customer_name": "April Hughes",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Nicole W.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-06-12",
        "review_rate": 5.0,
        "customer_name": "Robert Osborne",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Eric M.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-10-04",
        "review_rate": 4.8,
        "customer_name": "Christina Fernandez",
        "service_type": "Garage Door Installation",
        "tech_name": "Lauren P.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-02-28",
        "review_rate": 4.9,
        "customer_name": "Steven Perez",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Margaret B.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-09-09",
        "review_rate": 4.9,
        "customer_name": "Julie Smith",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Kristin H.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-05-29",
        "review_rate": 5.0,
        "customer_name": "Mrs. Susan Wagner",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Jonathan B.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-03-12",
        "review_rate": 4.8,
        "customer_name": "Denise Elliott",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Timothy C.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-02-02",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Hill MD",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Kimberly M.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-11-02",
        "review_rate": 4.8,
        "customer_name": "Debra Williams",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Martin H.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-01-18",
        "review_rate": 4.8,
        "customer_name": "Edward Hernandez",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Andrea L.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-09-25",
        "review_rate": 5.0,
        "customer_name": "Caleb Roberts",
        "service_type": "Garage Door Opener",
        "tech_name": "Michael R.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-02-25",
        "review_rate": 4.9,
        "customer_name": "Nathan Blake",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Bryce E.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-02",
        "review_rate": 5.0,
        "customer_name": "James Johnson",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Jeremy L.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-05-12",
        "review_rate": 4.9,
        "customer_name": "Carlos Mays",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Linda S.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-08-18",
        "review_rate": 4.9,
        "customer_name": "Robert Mercer",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Elizabeth S.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-14",
        "review_rate": 4.9,
        "customer_name": "Ray Krueger",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Jennifer R.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-01-27",
        "review_rate": 4.8,
        "customer_name": "Rebekah Elliott",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Nicole L.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-03-08",
        "review_rate": 4.9,
        "customer_name": "Michael Hampton",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Debra C.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-10-20",
        "review_rate": 4.9,
        "customer_name": "Donna Riley",
        "service_type": "Garage Door Installation",
        "tech_name": "Mark C.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-06-11",
        "review_rate": 4.9,
        "customer_name": "Ronnie Rivas",
        "service_type": "Garage Door Opener",
        "tech_name": "Heidi C.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-01-20",
        "review_rate": 4.9,
        "customer_name": "Alexandra Goodwin",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Carrie G.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-09-29",
        "review_rate": 5.0,
        "customer_name": "Matthew Sparks",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Dennis E.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-04-29",
        "review_rate": 4.8,
        "customer_name": "Cheryl Lopez",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Larry J.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-01-12",
        "review_rate": 5.0,
        "customer_name": "Jason Sweeney",
        "service_type": "Garage Door Services",
        "tech_name": "Stephen M.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-05-18",
        "review_rate": 4.9,
        "customer_name": "Kevin Allen",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Ashley J.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-03-13",
        "review_rate": 4.8,
        "customer_name": "Jason Morris",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Mark H.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-02-02",
        "review_rate": 4.8,
        "customer_name": "Cathy Franklin",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Tiffany G.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-05-23",
        "review_rate": 5.0,
        "customer_name": "Sabrina Obrien",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Drew W.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-10-29",
        "review_rate": 4.9,
        "customer_name": "Lisa Evans",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Jimmy B.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-07-29",
        "review_rate": 5.0,
        "customer_name": "Randall Sanders",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Amanda W.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-04-14",
        "review_rate": 4.9,
        "customer_name": "Courtney Schmitt",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Shawn R.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-11-23",
        "review_rate": 5.0,
        "customer_name": "Stephanie Wood",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Roger G.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-11-22",
        "review_rate": 4.8,
        "customer_name": "Alexander Madden",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Robert R.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-02-07",
        "review_rate": 5.0,
        "customer_name": "Mr. Gavin Patrick",
        "service_type": "Garage Door Services",
        "tech_name": "Tara J.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-09-04",
        "review_rate": 4.8,
        "customer_name": "William Hall",
        "service_type": "Garage Door Installation",
        "tech_name": "Ronald P.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-06-13",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Jimenez",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Emily H.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-06-05",
        "review_rate": 5.0,
        "customer_name": "Eric Jordan",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Jeremy C.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-07-11",
        "review_rate": 5.0,
        "customer_name": "Steven Burke",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Vanessa H.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-02-26",
        "review_rate": 5.0,
        "customer_name": "Lori Roy",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Marvin D.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-08-05",
        "review_rate": 4.9,
        "customer_name": "Michele Price",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Kelly T.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-07-14",
        "review_rate": 5.0,
        "customer_name": "Debra Knight",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Jennifer M.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-04-17",
        "review_rate": 4.9,
        "customer_name": "Nina Davis",
        "service_type": "Garage Door Repair",
        "tech_name": "Sandra B.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-08-18",
        "review_rate": 4.9,
        "customer_name": "Jillian Sims",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Jessica C.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-02-26",
        "review_rate": 4.9,
        "customer_name": "Amy Huynh",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Carlos B.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-08-22",
        "review_rate": 4.9,
        "customer_name": "Chase Hernandez",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Johnny L.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-09-03",
        "review_rate": 5.0,
        "customer_name": "Jeffery Ewing",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Mary M.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-04-23",
        "review_rate": 4.8,
        "customer_name": "Dr. Jennifer Franklin",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Kayla B.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-01-25",
        "review_rate": 4.9,
        "customer_name": "Gregory Lopez",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jessica B.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-03-07",
        "review_rate": 4.9,
        "customer_name": "Alexis Powell",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Brittany A.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-02-14",
        "review_rate": 5.0,
        "customer_name": "Penny Russo",
        "service_type": "Garage Door Services",
        "tech_name": "Natalie B.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-09-15",
        "review_rate": 5.0,
        "customer_name": "Andrew Herrera",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Mary K.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-09-05",
        "review_rate": 4.8,
        "customer_name": "Michelle Burnett",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Melissa N.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-11-15",
        "review_rate": 5.0,
        "customer_name": "Jason Pittman",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Felicia H.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-01-08",
        "review_rate": 5.0,
        "customer_name": "Samuel Hooper",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Charles R.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-07-12",
        "review_rate": 4.8,
        "customer_name": "Kara Hamilton",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Colleen R.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-02-23",
        "review_rate": 5.0,
        "customer_name": "Mr. Jason Dennis",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jillian G.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-01-01",
        "review_rate": 5.0,
        "customer_name": "Travis Stokes",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Kim B.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-09-05",
        "review_rate": 4.9,
        "customer_name": "David Ferrell",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Deanna S.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-11-25",
        "review_rate": 4.8,
        "customer_name": "Michael Schultz",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Jeffrey C.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-08-11",
        "review_rate": 5.0,
        "customer_name": "Brittany Reed",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Howard M.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-01-14",
        "review_rate": 4.9,
        "customer_name": "Karen Terry",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Nicole J.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-04",
        "review_rate": 4.9,
        "customer_name": "Rebecca Alexander",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Anthony S.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-02-07",
        "review_rate": 5.0,
        "customer_name": "Natasha Collier",
        "service_type": "Garage Door Opener",
        "tech_name": "Walter R.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-11-07",
        "review_rate": 4.9,
        "customer_name": "Olivia Powell",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Jennifer F.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-31",
        "review_rate": 5.0,
        "customer_name": "Jennifer Casey",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Meghan H.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-07-23",
        "review_rate": 4.8,
        "customer_name": "Jacob Flores",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Steven N.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-09-12",
        "review_rate": 4.9,
        "customer_name": "Jennifer Obrien",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Michael H.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-11-26",
        "review_rate": 4.9,
        "customer_name": "John Wilcox",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Christina M.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-05-19",
        "review_rate": 5.0,
        "customer_name": "Diane Long",
        "service_type": "Garage Door Repair",
        "tech_name": "Angela H.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-04-04",
        "review_rate": 4.8,
        "customer_name": "Brandon Barnes",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Heather G.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-21",
        "review_rate": 5.0,
        "customer_name": "Amy Green",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Chad J.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-09-09",
        "review_rate": 4.8,
        "customer_name": "Dr. Angela Whitehead",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Whitney W.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-08-10",
        "review_rate": 5.0,
        "customer_name": "Rhonda Green",
        "service_type": "Custom Garage Door Design",
        "tech_name": "George O.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-11-28",
        "review_rate": 5.0,
        "customer_name": "Stephanie Bernard",
        "service_type": "Garage Door Off Track",
        "tech_name": "Joshua V.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-07-02",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Newman MD",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Tammy Z.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-12-28",
        "review_rate": 4.9,
        "customer_name": "Angelica Doyle",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Monica B.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-09-15",
        "review_rate": 4.8,
        "customer_name": "Jose Lam",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Nathaniel C.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-29",
        "review_rate": 5.0,
        "customer_name": "Kimberly Orozco",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Tammy P.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-09-04",
        "review_rate": 5.0,
        "customer_name": "Andrew Hill",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Nicole C.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-03-19",
        "review_rate": 4.8,
        "customer_name": "Andrew Summers",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jamie M.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-13",
        "review_rate": 5.0,
        "customer_name": "Angela Garrett",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Jeremy B.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-08-30",
        "review_rate": 4.8,
        "customer_name": "Teresa Cunningham",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Catherine D.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-20",
        "review_rate": 4.9,
        "customer_name": "Amy Moore",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Anthony H.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-11-23",
        "review_rate": 5.0,
        "customer_name": "Omar Parker",
        "service_type": "Garage Door Services",
        "tech_name": "Juan E.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-10",
        "review_rate": 4.8,
        "customer_name": "Douglas Martinez",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Maria A.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-07-11",
        "review_rate": 4.9,
        "customer_name": "Ann Robertson",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Allison M.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-03",
        "review_rate": 4.8,
        "customer_name": "Donald Cain",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Gail F.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-23",
        "review_rate": 4.9,
        "customer_name": "Russell Vasquez",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Daisy M.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-05-17",
        "review_rate": 4.9,
        "customer_name": "Scott Greene",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Natalie D.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-28",
        "review_rate": 5.0,
        "customer_name": "Tanya Thomas",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "William W.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-22",
        "review_rate": 4.9,
        "customer_name": "Alexandria White",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Joel D.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-09",
        "review_rate": 4.9,
        "customer_name": "Scott Miller",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Hayley S.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-07-13",
        "review_rate": 5.0,
        "customer_name": "Katherine Hill DDS",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Lisa T.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-01-07",
        "review_rate": 4.9,
        "customer_name": "Emily Shaw",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Robert H.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-24",
        "review_rate": 4.8,
        "customer_name": "Kevin Rivera",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Julian S.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-12-01",
        "review_rate": 4.9,
        "customer_name": "Patrick Peterson",
        "service_type": "Garage Door Opener",
        "tech_name": "Brianna T.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-09-29",
        "review_rate": 4.9,
        "customer_name": "Kelly Golden",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Kelly P.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-01-28",
        "review_rate": 4.8,
        "customer_name": "Sherry Larsen",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "David D.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-05-03",
        "review_rate": 4.9,
        "customer_name": "Joyce Delacruz",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Dana O.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-03-09",
        "review_rate": 4.9,
        "customer_name": "Laura Rios",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Shelly W.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-06-03",
        "review_rate": 5.0,
        "customer_name": "Sean Long",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Brian T.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-06-28",
        "review_rate": 5.0,
        "customer_name": "Todd Klein",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Allen B.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-03-17",
        "review_rate": 4.8,
        "customer_name": "Taylor Jackson",
        "service_type": "Garage Door Repair",
        "tech_name": "Christopher S.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-12-25",
        "review_rate": 5.0,
        "customer_name": "Nicholas Brewer",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Robert R.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-05-11",
        "review_rate": 4.8,
        "customer_name": "Robert Terry",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Stephanie D.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-12",
        "review_rate": 4.9,
        "customer_name": "Luke Walker",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "John G.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-03-14",
        "review_rate": 4.8,
        "customer_name": "Andrew Miller",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "James J.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-05-29",
        "review_rate": 4.9,
        "customer_name": "Lisa Cain",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Jeffrey A.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-11",
        "review_rate": 5.0,
        "customer_name": "Gregory Watson",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Allison T.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-09-23",
        "review_rate": 4.8,
        "customer_name": "Suzanne Anderson",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Yvonne J.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-28",
        "review_rate": 4.9,
        "customer_name": "Travis Guzman",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Mary D.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-12-25",
        "review_rate": 4.8,
        "customer_name": "Patricia Cooper",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Kurt B.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-09-20",
        "review_rate": 4.8,
        "customer_name": "Lucas Schaefer",
        "service_type": "Garage Door Off Track",
        "tech_name": "Haley B.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-06-26",
        "review_rate": 4.9,
        "customer_name": "William Floyd",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Jeffrey H.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-12-03",
        "review_rate": 5.0,
        "customer_name": "Heather Miller",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Eric D.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-11-19",
        "review_rate": 4.8,
        "customer_name": "Dennis Bailey",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Jacqueline H.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-06-23",
        "review_rate": 4.8,
        "customer_name": "Debra Berger",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Samuel P.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-03-06",
        "review_rate": 5.0,
        "customer_name": "Rachel Jackson",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Jerome P.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-12-21",
        "review_rate": 4.9,
        "customer_name": "Bryan Moore",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Kathryn V.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-06",
        "review_rate": 4.9,
        "customer_name": "Natalie Thomas",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Travis C.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-09-14",
        "review_rate": 4.9,
        "customer_name": "Felicia Cooper",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Michael Y.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-12-16",
        "review_rate": 5.0,
        "customer_name": "Stephanie Turner DDS",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Jeffrey J.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-05-31",
        "review_rate": 4.8,
        "customer_name": "Henry Jackson",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "James V.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-03",
        "review_rate": 4.9,
        "customer_name": "David Mccarthy",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "John C.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-11-17",
        "review_rate": 4.9,
        "customer_name": "Michael Anderson II",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Tracey C.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-02-11",
        "review_rate": 4.9,
        "customer_name": "John Mccoy",
        "service_type": "Garage Door Repair",
        "tech_name": "Dustin R.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-09-29",
        "review_rate": 4.9,
        "customer_name": "Michelle Gonzales",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Kimberly J.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-09-18",
        "review_rate": 4.9,
        "customer_name": "Aaron Gardner",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Ashlee S.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-05-20",
        "review_rate": 4.9,
        "customer_name": "Joann Scott",
        "service_type": "Garage Door Installation",
        "tech_name": "Samuel P.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-06",
        "review_rate": 4.9,
        "customer_name": "Bradley Wright",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Mary J.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-08-18",
        "review_rate": 5.0,
        "customer_name": "Anthony Mcpherson",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Margaret B.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-10-14",
        "review_rate": 4.8,
        "customer_name": "George Mills",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Michael Y.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-07-12",
        "review_rate": 5.0,
        "customer_name": "Alan Powell",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Linda B.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-11-12",
        "review_rate": 4.9,
        "customer_name": "Theresa Martinez",
        "service_type": "Garage Door Opener",
        "tech_name": "Kimberly F.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-09-09",
        "review_rate": 4.8,
        "customer_name": "Janet Bass",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Lauren H.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-05",
        "review_rate": 4.8,
        "customer_name": "Sean Matthews",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Edward L.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-05-13",
        "review_rate": 4.8,
        "customer_name": "Rose Richards",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Vanessa S.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-02",
        "review_rate": 4.8,
        "customer_name": "Casey Wilson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Rachael M.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-11-19",
        "review_rate": 4.9,
        "customer_name": "Ryan Carson",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Bryan C.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-04-15",
        "review_rate": 4.9,
        "customer_name": "Matthew Johnson",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Amanda T.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-06-05",
        "review_rate": 4.8,
        "customer_name": "Brandi Ramirez",
        "service_type": "Garage Door Off Track",
        "tech_name": "Christopher W.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-07-04",
        "review_rate": 4.9,
        "customer_name": "Victoria Barker",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Phillip C.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-03-04",
        "review_rate": 4.8,
        "customer_name": "Kimberly Davis",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Ryan O.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-11-25",
        "review_rate": 4.9,
        "customer_name": "Charles Bradford",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Charles G.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-03-30",
        "review_rate": 4.9,
        "customer_name": "Shannon Torres",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Lisa B.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-06-27",
        "review_rate": 4.9,
        "customer_name": "Stephanie Williams",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Dawn S.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-04-23",
        "review_rate": 4.9,
        "customer_name": "Samantha Boyle",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Christine D.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-12-30",
        "review_rate": 4.8,
        "customer_name": "Jessica Ball",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Travis F.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-22",
        "review_rate": 4.8,
        "customer_name": "Eric Rice",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Cassandra L.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-12-10",
        "review_rate": 4.9,
        "customer_name": "Erik Bryant",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Karen B.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-03-31",
        "review_rate": 4.8,
        "customer_name": "Virginia Patton",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Brandon H.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-10-04",
        "review_rate": 4.9,
        "customer_name": "Matthew Davis",
        "service_type": "Garage Door Opener",
        "tech_name": "Shannon J.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-07-26",
        "review_rate": 4.9,
        "customer_name": "Christopher Williams",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Ashley H.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-02-13",
        "review_rate": 4.9,
        "customer_name": "Theresa Kennedy",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Collin H.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-10-24",
        "review_rate": 5.0,
        "customer_name": "Melissa Kerr",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Caitlyn S.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-06-26",
        "review_rate": 4.9,
        "customer_name": "Nicholas Moore",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Brenda T.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-03-08",
        "review_rate": 4.8,
        "customer_name": "Daniel Foster",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Tracy G.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-02-06",
        "review_rate": 4.8,
        "customer_name": "Kathleen Roberts",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Karen T.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-12-07",
        "review_rate": 4.9,
        "customer_name": "Shannon Wilson",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Andrew S.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-06-24",
        "review_rate": 4.9,
        "customer_name": "Joshua Lyons",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Heidi R.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-04-28",
        "review_rate": 5.0,
        "customer_name": "Diana Allen",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "James V.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-02-16",
        "review_rate": 5.0,
        "customer_name": "Megan Moore",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Patrick S.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-03-10",
        "review_rate": 4.9,
        "customer_name": "Zachary Stone",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Darin O.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-02-17",
        "review_rate": 5.0,
        "customer_name": "Jay Manning",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Jamie T.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-10-01",
        "review_rate": 5.0,
        "customer_name": "Michael Graves",
        "service_type": "Garage Door Repair",
        "tech_name": "Raymond S.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-11-30",
        "review_rate": 4.9,
        "customer_name": "Tiffany Henry",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Thomas T.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-11-05",
        "review_rate": 4.9,
        "customer_name": "Daniel Lynch",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Carl M.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-08",
        "review_rate": 5.0,
        "customer_name": "Nicholas Peterson",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Shawn G.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-11-21",
        "review_rate": 4.9,
        "customer_name": "Steven Monroe",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Robert B.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-12-25",
        "review_rate": 4.9,
        "customer_name": "Alyssa Banks",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Timothy S.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-11-05",
        "review_rate": 5.0,
        "customer_name": "April Young",
        "service_type": "Garage Door Off Track",
        "tech_name": "Caitlyn H.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-03-19",
        "review_rate": 4.9,
        "customer_name": "David Williams",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Alexandra H.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-08",
        "review_rate": 4.8,
        "customer_name": "Robert Mejia",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Daniel G.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-06-08",
        "review_rate": 4.9,
        "customer_name": "Melissa Lopez",
        "service_type": "Garage Door Repair",
        "tech_name": "Kenneth D.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-07-24",
        "review_rate": 4.8,
        "customer_name": "Danielle Powell",
        "service_type": "Garage Door Insulation",
        "tech_name": "Kristin A.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-28",
        "review_rate": 4.9,
        "customer_name": "Christopher Wright",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Brian S.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-06-10",
        "review_rate": 4.8,
        "customer_name": "Frank Jordan",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Hannah C.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-09-28",
        "review_rate": 5.0,
        "customer_name": "Chad Larson",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Eugene V.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-05-26",
        "review_rate": 5.0,
        "customer_name": "Omar Browning",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Jenna R.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-01-02",
        "review_rate": 4.9,
        "customer_name": "Laurie Hensley",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Cassandra B.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-11-06",
        "review_rate": 4.9,
        "customer_name": "Caroline Johnson",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Felicia W.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-07-09",
        "review_rate": 5.0,
        "customer_name": "Courtney Harrington",
        "service_type": "Garage Door Opener",
        "tech_name": "Jacob S.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-10-23",
        "review_rate": 4.9,
        "customer_name": "Cynthia Hall",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "David F.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-09-25",
        "review_rate": 4.8,
        "customer_name": "Jennifer Smith",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Jordan T.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-07-25",
        "review_rate": 4.9,
        "customer_name": "April Sanchez",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Michael W.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-02-21",
        "review_rate": 4.8,
        "customer_name": "Scott Clark",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Zachary S.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-07-01",
        "review_rate": 4.8,
        "customer_name": "Brian Brown",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Corey T.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-29",
        "review_rate": 4.9,
        "customer_name": "Daniel Yates",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Elizabeth H.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-02-10",
        "review_rate": 4.9,
        "customer_name": "Francisco Robinson",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Jacqueline S.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-02-10",
        "review_rate": 4.8,
        "customer_name": "Billy Duran",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Brett W.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-10-08",
        "review_rate": 5.0,
        "customer_name": "Joshua Kelly",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Kevin N.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-09-12",
        "review_rate": 5.0,
        "customer_name": "Kayla Hull",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Cheryl S.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-11-15",
        "review_rate": 4.9,
        "customer_name": "Lance Schroeder",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Amanda R.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-11-12",
        "review_rate": 4.9,
        "customer_name": "Joshua Davidson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Devin S.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-10-06",
        "review_rate": 4.9,
        "customer_name": "Ethan Roberts",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Richard M.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-10-27",
        "review_rate": 4.9,
        "customer_name": "Lisa Cantrell",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Kathryn C.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-01",
        "review_rate": 4.9,
        "customer_name": "Megan Matthews",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Alicia H.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-05-04",
        "review_rate": 4.9,
        "customer_name": "Jeffery Case",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Samuel B.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-05-02",
        "review_rate": 4.9,
        "customer_name": "Jessica Valentine",
        "service_type": "Garage Door Off Track",
        "tech_name": "Andrew W.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-05-08",
        "review_rate": 4.9,
        "customer_name": "Eric Rogers",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Bryan D.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-29",
        "review_rate": 4.8,
        "customer_name": "Michael Morgan",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Monica B.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-12-31",
        "review_rate": 5.0,
        "customer_name": "Jake Mitchell",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Justin A.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-06-27",
        "review_rate": 5.0,
        "customer_name": "Mike Spencer",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Matthew L.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-11-14",
        "review_rate": 5.0,
        "customer_name": "Dr. Tonya Wang",
        "service_type": "Garage Door Off Track",
        "tech_name": "Alicia Y.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-02-03",
        "review_rate": 5.0,
        "customer_name": "Christine Gilbert",
        "service_type": "Garage Door Services",
        "tech_name": "Charles F.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-10-19",
        "review_rate": 4.8,
        "customer_name": "Kimberly Crawford",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Jesus M.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-07-18",
        "review_rate": 5.0,
        "customer_name": "Stephanie Moore",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Monica B.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-09-17",
        "review_rate": 4.8,
        "customer_name": "Rebecca Levy",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Jennifer B.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-04-26",
        "review_rate": 4.9,
        "customer_name": "Dennis Anderson",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Andrew C.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-03",
        "review_rate": 4.9,
        "customer_name": "David Baxter",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Erica P.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-10",
        "review_rate": 4.8,
        "customer_name": "Veronica Kennedy",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Ryan N.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-12-28",
        "review_rate": 4.8,
        "customer_name": "Alexandria Owen",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "George M.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-08",
        "review_rate": 5.0,
        "customer_name": "Kristen Watson",
        "service_type": "Custom Garage Door Design",
        "tech_name": "David P.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-12-28",
        "review_rate": 4.9,
        "customer_name": "Brittany James",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Alyssa G.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-01-16",
        "review_rate": 5.0,
        "customer_name": "Stephanie Montoya",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Brian M.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-26",
        "review_rate": 4.9,
        "customer_name": "Jenna Summers",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "John F.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-12-24",
        "review_rate": 4.9,
        "customer_name": "Timothy Stewart",
        "service_type": "Garage Door Off Track",
        "tech_name": "Megan S.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-01-05",
        "review_rate": 4.9,
        "customer_name": "Daniel Reed",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Matthew S.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-09-25",
        "review_rate": 4.9,
        "customer_name": "Jake Williams",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Diana S.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-10",
        "review_rate": 4.9,
        "customer_name": "Danielle Brown",
        "service_type": "Garage Door Repair",
        "tech_name": "Jeffrey G.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-04-18",
        "review_rate": 4.9,
        "customer_name": "Barbara Schultz",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Leslie W.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-12-16",
        "review_rate": 4.9,
        "customer_name": "Katie Kennedy",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Aaron O.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-02-18",
        "review_rate": 4.9,
        "customer_name": "Stacey Sutton",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "David D.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-04-20",
        "review_rate": 4.9,
        "customer_name": "William Hopkins",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Edward H.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-24",
        "review_rate": 4.8,
        "customer_name": "Christy Nash",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "James W.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-04-17",
        "review_rate": 4.9,
        "customer_name": "Jeremy Mason",
        "service_type": "Garage Door Installation",
        "tech_name": "William S.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-03-09",
        "review_rate": 4.9,
        "customer_name": "David Parker",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Peter G.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-02-29",
        "review_rate": 4.9,
        "customer_name": "Misty English",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Darrell C.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-09-13",
        "review_rate": 4.9,
        "customer_name": "Kelly Bailey",
        "service_type": "Garage Door Opener",
        "tech_name": "Haley C.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-02-28",
        "review_rate": 5.0,
        "customer_name": "Terri Anderson",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Jason C.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-01-17",
        "review_rate": 4.9,
        "customer_name": "Carl Bates",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "William S.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-03-26",
        "review_rate": 5.0,
        "customer_name": "Mark Spencer",
        "service_type": "Garage Door Installation",
        "tech_name": "Justin R.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-09-21",
        "review_rate": 4.9,
        "customer_name": "Jamie Bennett",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Paul B.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-10-18",
        "review_rate": 4.8,
        "customer_name": "Hunter Cruz",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Paul H.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-11-28",
        "review_rate": 4.9,
        "customer_name": "Gregory Cook",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Grace H.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-12-17",
        "review_rate": 4.8,
        "customer_name": "Tyler Holland",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Peter T.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-10-16",
        "review_rate": 5.0,
        "customer_name": "Brian Johnson",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Kevin R.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-03-01",
        "review_rate": 4.8,
        "customer_name": "Christopher Richardson",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Joshua M.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-06-21",
        "review_rate": 4.8,
        "customer_name": "Steven Jones",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Traci L.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-09-09",
        "review_rate": 5.0,
        "customer_name": "Cory Saunders",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Eric P.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-08-31",
        "review_rate": 5.0,
        "customer_name": "Bethany Coleman",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Kristen M.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-08-21",
        "review_rate": 4.9,
        "customer_name": "Sandra Todd",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "James M.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-03-09",
        "review_rate": 4.9,
        "customer_name": "Russell Cruz",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Mark J.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-12-13",
        "review_rate": 5.0,
        "customer_name": "Sheila Williams",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Lisa G.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-10-20",
        "review_rate": 4.8,
        "customer_name": "Bruce Dougherty",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Jill M.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-02-20",
        "review_rate": 4.9,
        "customer_name": "Nicholas Lewis",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Peggy J.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-10-15",
        "review_rate": 5.0,
        "customer_name": "Joy Glass",
        "service_type": "Garage Door Repair",
        "tech_name": "Savannah R.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-05-07",
        "review_rate": 5.0,
        "customer_name": "Melissa Perez",
        "service_type": "Garage Door Services",
        "tech_name": "Kayla H.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-03-25",
        "review_rate": 4.8,
        "customer_name": "Frank Bennett",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Antonio C.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-06-14",
        "review_rate": 4.8,
        "customer_name": "Sandra Williams",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Thomas B.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-08-13",
        "review_rate": 5.0,
        "customer_name": "Kyle Huber",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Jessica M.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-12-21",
        "review_rate": 4.8,
        "customer_name": "Vickie Rogers",
        "service_type": "Garage Door Installation",
        "tech_name": "Benjamin R.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-05-16",
        "review_rate": 4.8,
        "customer_name": "Paul Flores",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Tyler D.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-07-21",
        "review_rate": 4.9,
        "customer_name": "David Jones",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Robert T.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-04-06",
        "review_rate": 4.8,
        "customer_name": "Kevin Long",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Derek E.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-06-03",
        "review_rate": 4.9,
        "customer_name": "Kevin Grimes",
        "service_type": "Garage Door Services",
        "tech_name": "David S.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-06-19",
        "review_rate": 4.9,
        "customer_name": "Sandra Walker",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Natalie S.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-11-13",
        "review_rate": 4.9,
        "customer_name": "Jose Phillips",
        "service_type": "Garage Door Repair",
        "tech_name": "Joseph S.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-03-05",
        "review_rate": 4.9,
        "customer_name": "Lisa Myers",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Jeff A.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-02-20",
        "review_rate": 4.9,
        "customer_name": "Christopher Wells",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Traci S.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-04-29",
        "review_rate": 4.8,
        "customer_name": "Jenny Hernandez MD",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Madison J.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-11-11",
        "review_rate": 4.9,
        "customer_name": "Douglas Higgins",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Ricky F.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-07-05",
        "review_rate": 4.9,
        "customer_name": "Tara French",
        "service_type": "Garage Door Services",
        "tech_name": "Douglas B.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-11-22",
        "review_rate": 4.8,
        "customer_name": "Martin Sullivan",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Kelly C.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-05-18",
        "review_rate": 4.9,
        "customer_name": "Megan Lewis",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Barbara A.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-03-03",
        "review_rate": 4.9,
        "customer_name": "Kevin George",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Jamie L.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-08-29",
        "review_rate": 5.0,
        "customer_name": "Miguel Alvarez",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Amy L.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-03-24",
        "review_rate": 4.8,
        "customer_name": "Elizabeth Patrick",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Lynn Z.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-03-25",
        "review_rate": 4.9,
        "customer_name": "Roberto Klein",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Jonathan L.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-12-16",
        "review_rate": 4.8,
        "customer_name": "Brandy Garcia",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Eric C.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-04-03",
        "review_rate": 5.0,
        "customer_name": "Michael King",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Alexandria L.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-04-10",
        "review_rate": 4.9,
        "customer_name": "Kendra Blake",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "David Y.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-10-14",
        "review_rate": 5.0,
        "customer_name": "Jeff Kelley",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Steven J.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-09-05",
        "review_rate": 5.0,
        "customer_name": "Gilbert Riley",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Gregory W.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-10-12",
        "review_rate": 5.0,
        "customer_name": "Maurice Koch",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Michele H.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-08-26",
        "review_rate": 4.9,
        "customer_name": "Julie Massey",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Ryan A.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-06-15",
        "review_rate": 4.9,
        "customer_name": "Ronald Ramsey",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Renee W.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-08-19",
        "review_rate": 5.0,
        "customer_name": "Kathryn Garcia",
        "service_type": "Custom Garage Door Design",
        "tech_name": "William H.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-11-17",
        "review_rate": 4.9,
        "customer_name": "Anthony Smith",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Samantha H.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-03-31",
        "review_rate": 4.9,
        "customer_name": "Kaitlin Thomas",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Heather T.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-01-03",
        "review_rate": 4.8,
        "customer_name": "Nicholas Webb",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Morgan R.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-01-06",
        "review_rate": 4.9,
        "customer_name": "Tina Chen",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Paul G.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-11-14",
        "review_rate": 5.0,
        "customer_name": "Kelly Mata",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Katelyn R.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-04-26",
        "review_rate": 4.9,
        "customer_name": "Nicole Kelley",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Mary C.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-07-07",
        "review_rate": 4.8,
        "customer_name": "Joanna Rasmussen",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "David S.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-08-30",
        "review_rate": 4.8,
        "customer_name": "Kimberly Thompson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Bailey W.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-06-24",
        "review_rate": 4.8,
        "customer_name": "Ryan Rios",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Jean M.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-12-31",
        "review_rate": 4.9,
        "customer_name": "Anthony Powers",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Cindy W.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-10-24",
        "review_rate": 5.0,
        "customer_name": "Erik Allen",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Mary L.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-02-24",
        "review_rate": 4.9,
        "customer_name": "Michael Frazier",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Julia S.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-11-27",
        "review_rate": 4.9,
        "customer_name": "Jamie Lawrence",
        "service_type": "Garage Door Repair",
        "tech_name": "Marc F.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-03-27",
        "review_rate": 5.0,
        "customer_name": "Cory Wood",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Tara H.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-09-25",
        "review_rate": 4.9,
        "customer_name": "Sarah Vazquez",
        "service_type": "Garage Door Opener",
        "tech_name": "Candice F.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-09-07",
        "review_rate": 4.9,
        "customer_name": "Ryan Bradley",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Tracy A.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-04-08",
        "review_rate": 4.9,
        "customer_name": "Taylor Tapia",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Roger M.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-01-01",
        "review_rate": 5.0,
        "customer_name": "Michael Evans",
        "service_type": "Garage Door Repair",
        "tech_name": "Courtney E.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-07-03",
        "review_rate": 4.9,
        "customer_name": "Wendy Sanders",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Michelle R.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-11-16",
        "review_rate": 4.9,
        "customer_name": "Michael Hernandez",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Eric F.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-06-10",
        "review_rate": 4.9,
        "customer_name": "Mary Fisher",
        "service_type": "Garage Door Installation",
        "tech_name": "Michael G.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-08-02",
        "review_rate": 4.8,
        "customer_name": "Rebecca Hernandez",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Jennifer H.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-05-09",
        "review_rate": 4.8,
        "customer_name": "Ricky Richard",
        "service_type": "Garage Door Off Track",
        "tech_name": "William C.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-09-14",
        "review_rate": 4.8,
        "customer_name": "Brooke Wagner",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Steven R.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-07-24",
        "review_rate": 4.9,
        "customer_name": "Amanda Williamson",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Anthony B.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-09-22",
        "review_rate": 5.0,
        "customer_name": "Theresa Garcia",
        "service_type": "Garage Door Services",
        "tech_name": "Cynthia D.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-03-09",
        "review_rate": 4.8,
        "customer_name": "Teresa Garcia",
        "service_type": "Garage Door Insulation",
        "tech_name": "Jasmine D.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-09-27",
        "review_rate": 4.8,
        "customer_name": "Christian Cole",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "William P.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-08-27",
        "review_rate": 4.9,
        "customer_name": "Tiffany Fowler",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Deborah Z.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-01-05",
        "review_rate": 5.0,
        "customer_name": "April Hopkins",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Tammy H.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-08-04",
        "review_rate": 4.9,
        "customer_name": "Taylor Simpson",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Ashley W.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-03-12",
        "review_rate": 4.9,
        "customer_name": "Phillip Parsons",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Chad C.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-08-19",
        "review_rate": 4.9,
        "customer_name": "Megan Taylor",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Melissa C.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-08-20",
        "review_rate": 4.9,
        "customer_name": "Dr. Aaron Stanley",
        "service_type": "Garage Door Repair",
        "tech_name": "James H.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-10-27",
        "review_rate": 4.9,
        "customer_name": "Anthony Love",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "David H.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-02-02",
        "review_rate": 4.9,
        "customer_name": "Keith Williams",
        "service_type": "Garage Door Opener",
        "tech_name": "Chelsea C.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-07-18",
        "review_rate": 4.8,
        "customer_name": "Marcus Anderson",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Vanessa J.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-02-22",
        "review_rate": 5.0,
        "customer_name": "Mrs. Brenda Joseph",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Ralph B.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-12-16",
        "review_rate": 4.8,
        "customer_name": "Kevin Green",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Douglas C.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-01-02",
        "review_rate": 4.9,
        "customer_name": "Taylor Hunt",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Richard T.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-04-21",
        "review_rate": 4.8,
        "customer_name": "Brent Acevedo",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Gabriel G.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-05-24",
        "review_rate": 5.0,
        "customer_name": "Rick Martinez",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Katherine M.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-10-21",
        "review_rate": 4.8,
        "customer_name": "Kirk Camacho",
        "service_type": "Garage Door Services",
        "tech_name": "Richard L.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-03-14",
        "review_rate": 4.9,
        "customer_name": "Robert Guzman",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Nicholas P.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-07-17",
        "review_rate": 4.9,
        "customer_name": "Craig Lyons",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Jennifer N.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-12-11",
        "review_rate": 4.9,
        "customer_name": "Michelle Jones",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Patricia S.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-03-28",
        "review_rate": 4.9,
        "customer_name": "Alicia Brown",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Paula M.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-02-06",
        "review_rate": 4.9,
        "customer_name": "Melvin Wilkins",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Johnathan J.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-12-14",
        "review_rate": 4.9,
        "customer_name": "Janice Mcmillan",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Charles G.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-12-17",
        "review_rate": 4.9,
        "customer_name": "Megan James",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Robert G.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-05-02",
        "review_rate": 5.0,
        "customer_name": "Jasmine Taylor",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Raven V.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-05-05",
        "review_rate": 4.9,
        "customer_name": "Jessica Smith",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Brenda B.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-09-02",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Serrano",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Sydney G.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-02-13",
        "review_rate": 4.8,
        "customer_name": "Jonathan Ramirez",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Ashley M.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-03-22",
        "review_rate": 5.0,
        "customer_name": "Jeffrey Walker",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Amanda C.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-04-09",
        "review_rate": 4.8,
        "customer_name": "Hannah Decker",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Tommy G.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-08-14",
        "review_rate": 4.8,
        "customer_name": "Laura Thompson",
        "service_type": "Garage Door Installation",
        "tech_name": "Caleb W.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-10-04",
        "review_rate": 5.0,
        "customer_name": "Claudia Walter",
        "service_type": "Garage Door Services",
        "tech_name": "Tanya L.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-05-20",
        "review_rate": 4.8,
        "customer_name": "George Morgan",
        "service_type": "Garage Door Insulation",
        "tech_name": "Andrea R.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-02-07",
        "review_rate": 4.9,
        "customer_name": "Rebecca Jones",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Benjamin H.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-10-08",
        "review_rate": 4.9,
        "customer_name": "Jacob Leach",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Jacob M.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-08-11",
        "review_rate": 4.8,
        "customer_name": "Nicole Murphy",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Amanda G.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-06-23",
        "review_rate": 4.8,
        "customer_name": "Samuel Russell",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Virginia T.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-06-16",
        "review_rate": 4.9,
        "customer_name": "Jennifer Knight",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Dawn H.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-09-22",
        "review_rate": 5.0,
        "customer_name": "Colleen Byrd DVM",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Monica M.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-13",
        "review_rate": 4.8,
        "customer_name": "Eric Brown",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Jennifer M.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-01-20",
        "review_rate": 4.9,
        "customer_name": "Alan Li",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Jessica N.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-05-20",
        "review_rate": 4.9,
        "customer_name": "Christopher Holden",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Shannon S.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-02-07",
        "review_rate": 4.9,
        "customer_name": "Mckenzie Kelly",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Kim K.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-11-14",
        "review_rate": 4.9,
        "customer_name": "Joshua Alvarez",
        "service_type": "Garage Door Off Track",
        "tech_name": "Angela R.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-10-14",
        "review_rate": 5.0,
        "customer_name": "Kristin Morgan",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Elizabeth M.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-29",
        "review_rate": 5.0,
        "customer_name": "Tara Coleman MD",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Garrett K.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-02-25",
        "review_rate": 5.0,
        "customer_name": "Lori Martin",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Marie W.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-03-13",
        "review_rate": 4.9,
        "customer_name": "Mrs. Erin Williams",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Nicole S.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-27",
        "review_rate": 4.9,
        "customer_name": "Matthew Meyer",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Lauren H.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-04-09",
        "review_rate": 4.9,
        "customer_name": "Michael Fitzgerald",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Sarah L.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-10-23",
        "review_rate": 4.9,
        "customer_name": "Jacob Pierce",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Susan P.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-02-10",
        "review_rate": 4.8,
        "customer_name": "Manuel Powers",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Derek E.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-02-26",
        "review_rate": 4.9,
        "customer_name": "Patrick Clark",
        "service_type": "Garage Door Services",
        "tech_name": "Timothy T.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-06-19",
        "review_rate": 4.9,
        "customer_name": "Joshua Allen",
        "service_type": "Garage Door Insulation",
        "tech_name": "Julia P.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-11-28",
        "review_rate": 4.8,
        "customer_name": "Samantha Zamora",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Rebecca K.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-21",
        "review_rate": 5.0,
        "customer_name": "Austin Reynolds",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Angela P.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-10-26",
        "review_rate": 4.9,
        "customer_name": "Tammy Luna",
        "service_type": "Garage Door Repair",
        "tech_name": "Peggy S.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-12-11",
        "review_rate": 4.8,
        "customer_name": "Jose Mays",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Renee D.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-10-24",
        "review_rate": 4.9,
        "customer_name": "Alyssa Schneider",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Regina C.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-10-31",
        "review_rate": 4.8,
        "customer_name": "Briana Kirby",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Deanna V.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-10-24",
        "review_rate": 4.9,
        "customer_name": "Chris Stewart",
        "service_type": "Garage Door Opener",
        "tech_name": "Michael G.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-03",
        "review_rate": 4.8,
        "customer_name": "Justin Frank",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Katrina P.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-02-06",
        "review_rate": 4.8,
        "customer_name": "Patricia Knight",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Steven S.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-10-14",
        "review_rate": 4.9,
        "customer_name": "Christina Cline",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Olivia W.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-02-09",
        "review_rate": 4.9,
        "customer_name": "Corey Lucas",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Anthony R.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-03-12",
        "review_rate": 5.0,
        "customer_name": "James Williams",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Maria C.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-03-30",
        "review_rate": 5.0,
        "customer_name": "Eric Burton",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Tanner P.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-29",
        "review_rate": 4.9,
        "customer_name": "Deanna Medina",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Scott C.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-01-17",
        "review_rate": 4.9,
        "customer_name": "Heather Murray",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Adrienne S.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-05-17",
        "review_rate": 4.9,
        "customer_name": "Casey Bridges",
        "service_type": "Garage Door Installation",
        "tech_name": "Teresa B.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-04-25",
        "review_rate": 4.8,
        "customer_name": "Tina Martinez",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Christopher D.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-07-09",
        "review_rate": 4.9,
        "customer_name": "Lori Carrillo",
        "service_type": "Garage Door Installation",
        "tech_name": "Shawn S.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-03-27",
        "review_rate": 4.9,
        "customer_name": "Nicole Melton DDS",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Carrie P.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-09-14",
        "review_rate": 4.9,
        "customer_name": "Jonathan Reynolds",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Brandon G.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-07-05",
        "review_rate": 4.9,
        "customer_name": "William White",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Jeffrey L.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-03",
        "review_rate": 5.0,
        "customer_name": "Karina Moran",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Eric M.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-10-02",
        "review_rate": 5.0,
        "customer_name": "Steven Hall",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Bradley R.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-01-29",
        "review_rate": 4.9,
        "customer_name": "Katherine Martin",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Lisa B.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-06-15",
        "review_rate": 4.9,
        "customer_name": "Christine Harrison",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Thomas H.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-04-24",
        "review_rate": 5.0,
        "customer_name": "Shari Salazar",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "David B.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-19",
        "review_rate": 4.9,
        "customer_name": "Erica Holmes",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Linda S.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-08-22",
        "review_rate": 4.8,
        "customer_name": "Trevor Ryan",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Eric F.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-04-24",
        "review_rate": 4.9,
        "customer_name": "Ashley Fleming",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Paul F.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-02-01",
        "review_rate": 4.9,
        "customer_name": "Richard Charles",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Amanda W.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-03-17",
        "review_rate": 4.9,
        "customer_name": "Christopher Banks",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Brian O.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-11-05",
        "review_rate": 5.0,
        "customer_name": "Jessica Parker",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Matthew H.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-02",
        "review_rate": 4.9,
        "customer_name": "Jorge Rios",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Heather C.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-02-27",
        "review_rate": 4.9,
        "customer_name": "William Gomez",
        "service_type": "Garage Door Opener",
        "tech_name": "Kaylee W.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-11-10",
        "review_rate": 5.0,
        "customer_name": "Stephen Anderson",
        "service_type": "Garage Door Off Track",
        "tech_name": "Linda G.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-02",
        "review_rate": 4.9,
        "customer_name": "Deanna Taylor",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Shannon H.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-11-13",
        "review_rate": 5.0,
        "customer_name": "Matthew Taylor",
        "service_type": "Garage Door Installation",
        "tech_name": "Sarah S.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-07-20",
        "review_rate": 4.9,
        "customer_name": "Jenny Jenkins",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Paul B.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-09-22",
        "review_rate": 5.0,
        "customer_name": "James Estrada",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Alexandria R.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-11-09",
        "review_rate": 5.0,
        "customer_name": "Lori Brown",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Roger H.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-01",
        "review_rate": 4.9,
        "customer_name": "Alexandra Williams",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Martha R.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-09-08",
        "review_rate": 4.8,
        "customer_name": "Teresa Elliott",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Mary W.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-09-23",
        "review_rate": 4.8,
        "customer_name": "Cynthia Coleman",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Sarah K.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-07-08",
        "review_rate": 4.8,
        "customer_name": "Toni Butler",
        "service_type": "Garage Door Repair",
        "tech_name": "Mary R.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-06-20",
        "review_rate": 4.9,
        "customer_name": "Connie Ramirez",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Joshua R.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-08-15",
        "review_rate": 4.9,
        "customer_name": "Kristen Ortiz",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Brian A.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-02-05",
        "review_rate": 4.9,
        "customer_name": "Roy Allen",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Sandra F.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-04-03",
        "review_rate": 4.9,
        "customer_name": "Patricia Garza",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Brandon H.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-22",
        "review_rate": 4.8,
        "customer_name": "Kristopher Schultz",
        "service_type": "Garage Door Insulation",
        "tech_name": "Steven M.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-10-17",
        "review_rate": 4.9,
        "customer_name": "Joan Lopez",
        "service_type": "Garage Door Repair",
        "tech_name": "Sharon R.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-10-11",
        "review_rate": 4.8,
        "customer_name": "Mr. Justin Clark III",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Chris B.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-06-26",
        "review_rate": 4.9,
        "customer_name": "Paul Rice",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Michael T.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-01-07",
        "review_rate": 5.0,
        "customer_name": "Rachel Collins",
        "service_type": "Garage Door Insulation",
        "tech_name": "Joel M.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-03-08",
        "review_rate": 4.9,
        "customer_name": "Victor Barrett",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Christopher J.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-15",
        "review_rate": 4.9,
        "customer_name": "Jason Long",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Joseph M.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-01-30",
        "review_rate": 4.8,
        "customer_name": "Ryan Stephens",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Johnny M.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-08-31",
        "review_rate": 4.9,
        "customer_name": "Janet Winters",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Marvin H.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-04-04",
        "review_rate": 4.8,
        "customer_name": "Brett Haas",
        "service_type": "Garage Door Repair",
        "tech_name": "David H.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-06-14",
        "review_rate": 4.9,
        "customer_name": "Megan Tanner",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Jonathan H.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-07-04",
        "review_rate": 4.9,
        "customer_name": "Amanda Gallegos",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "William W.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-04-03",
        "review_rate": 5.0,
        "customer_name": "Scott Foley",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Erik W.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-11-06",
        "review_rate": 5.0,
        "customer_name": "Mary Torres",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Dawn F.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-01-28",
        "review_rate": 4.8,
        "customer_name": "Emily Delgado",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Corey H.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-11-05",
        "review_rate": 4.9,
        "customer_name": "Kathleen Hahn",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Amy S.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-05",
        "review_rate": 5.0,
        "customer_name": "Joseph Wilkerson DDS",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Amber B.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-02-18",
        "review_rate": 5.0,
        "customer_name": "Rebecca Gill",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "John S.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-02",
        "review_rate": 5.0,
        "customer_name": "Gregory Daniels",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Marc J.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-07-26",
        "review_rate": 4.9,
        "customer_name": "Mr. Alexander Griffin",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "David C.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-25",
        "review_rate": 5.0,
        "customer_name": "Justin Marshall",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Derek M.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-14",
        "review_rate": 4.9,
        "customer_name": "Mikayla Harrison",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Andrew D.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-04-12",
        "review_rate": 4.9,
        "customer_name": "David Zhang",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Brittney R.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-11-17",
        "review_rate": 4.9,
        "customer_name": "Jay Ramirez",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Glenn F.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-11-02",
        "review_rate": 4.9,
        "customer_name": "Dakota Everett DDS",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Alejandra S.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-02-03",
        "review_rate": 5.0,
        "customer_name": "Melissa Alvarez",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Thomas J.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-09",
        "review_rate": 4.9,
        "customer_name": "Mr. Mitchell Watson DVM",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Tara J.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-22",
        "review_rate": 4.8,
        "customer_name": "Victoria Paul DDS",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Robert M.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-11-30",
        "review_rate": 4.8,
        "customer_name": "Matthew Glass",
        "service_type": "Garage Door Off Track",
        "tech_name": "Kelly M.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-03-23",
        "review_rate": 4.9,
        "customer_name": "Frederick Ellis",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Elizabeth S.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-06-24",
        "review_rate": 4.9,
        "customer_name": "Michael Harrison",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Thomas L.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-05-07",
        "review_rate": 4.9,
        "customer_name": "Brandy Miles",
        "service_type": "Garage Door Services",
        "tech_name": "William F.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-06-14",
        "review_rate": 5.0,
        "customer_name": "Deborah Bell",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "John L.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-16",
        "review_rate": 5.0,
        "customer_name": "Gregory Foster",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Chelsea R.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-05",
        "review_rate": 5.0,
        "customer_name": "Keith Hansen",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Justin H.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-06-02",
        "review_rate": 4.9,
        "customer_name": "Jesse Taylor",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Adam B.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-04-02",
        "review_rate": 4.9,
        "customer_name": "Joseph Coleman",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Zachary P.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-02-04",
        "review_rate": 4.8,
        "customer_name": "Beth Mccall",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Karen S.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-04-27",
        "review_rate": 4.9,
        "customer_name": "John Lucas",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Dylan G.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-02-13",
        "review_rate": 4.9,
        "customer_name": "Melvin Williams",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Shawn L.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-25",
        "review_rate": 4.9,
        "customer_name": "Ariel Robinson",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Brett C.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-29",
        "review_rate": 4.8,
        "customer_name": "Sharon Leonard",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Nathan R.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-09-23",
        "review_rate": 4.9,
        "customer_name": "Samantha Kirk",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Shannon N.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-03-30",
        "review_rate": 4.9,
        "customer_name": "William Davidson",
        "service_type": "Garage Door Repair",
        "tech_name": "Joseph S.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-10",
        "review_rate": 4.9,
        "customer_name": "Allen Ali",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Mason R.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-03-03",
        "review_rate": 4.9,
        "customer_name": "Heidi Jackson",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Stephen A.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-11-17",
        "review_rate": 4.8,
        "customer_name": "Terry Robertson",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Gavin S.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-11-03",
        "review_rate": 4.9,
        "customer_name": "Tonya Cannon",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Benjamin P.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-11-27",
        "review_rate": 4.9,
        "customer_name": "Tracy Richardson",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Deborah R.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-02-28",
        "review_rate": 4.9,
        "customer_name": "Eric Turner",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Janet S.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-05-25",
        "review_rate": 4.9,
        "customer_name": "Joshua Nunez",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Gary J.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-12-22",
        "review_rate": 4.9,
        "customer_name": "Amy Miller",
        "service_type": "Garage Door Insulation",
        "tech_name": "Olivia B.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-11-25",
        "review_rate": 4.8,
        "customer_name": "Adrian Gardner",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Anita A.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-03-24",
        "review_rate": 4.9,
        "customer_name": "Charles Reed",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Michael R.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-11-29",
        "review_rate": 4.9,
        "customer_name": "Nicholas Smith",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Shannon G.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-15",
        "review_rate": 4.8,
        "customer_name": "Kimberly Jones",
        "service_type": "Garage Door Off Track",
        "tech_name": "Sabrina D.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-13",
        "review_rate": 4.9,
        "customer_name": "Christopher Mejia",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Richard A.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-07-26",
        "review_rate": 4.9,
        "customer_name": "Steven Gregory",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Christopher Y.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-05-24",
        "review_rate": 4.9,
        "customer_name": "Jordan Marquez",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Alexandra L.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-03-15",
        "review_rate": 4.9,
        "customer_name": "Donald Montoya",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Adam B.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-26",
        "review_rate": 4.9,
        "customer_name": "Stephanie Nguyen",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Willie J.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-07-06",
        "review_rate": 4.9,
        "customer_name": "Tiffany Giles",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Joseph R.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-03-11",
        "review_rate": 4.9,
        "customer_name": "Matthew Ray",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Steven H.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-12-22",
        "review_rate": 5.0,
        "customer_name": "Roger Miller",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Henry B.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-09-28",
        "review_rate": 4.9,
        "customer_name": "Donna Cruz",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Ashley S.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-31",
        "review_rate": 5.0,
        "customer_name": "Holly Arnold",
        "service_type": "Garage Door Services",
        "tech_name": "Tina A.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-06-23",
        "review_rate": 5.0,
        "customer_name": "Kelsey Morton",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Shannon F.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-11-29",
        "review_rate": 5.0,
        "customer_name": "Stephanie Smith",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Rebecca B.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-09-20",
        "review_rate": 4.9,
        "customer_name": "Dr. Ariel Underwood",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Jeffrey G.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-05-04",
        "review_rate": 4.8,
        "customer_name": "Amy Thompson",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Calvin K.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-11-17",
        "review_rate": 4.9,
        "customer_name": "Linda Martinez",
        "service_type": "Garage Door Insulation",
        "tech_name": "Sarah J.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-02-23",
        "review_rate": 4.9,
        "customer_name": "Emily White",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Howard G.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-02-24",
        "review_rate": 4.9,
        "customer_name": "Michael Cabrera",
        "service_type": "Garage Door Installation",
        "tech_name": "Brenda R.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-07-26",
        "review_rate": 4.9,
        "customer_name": "Brandon Lopez",
        "service_type": "Garage Door Repair",
        "tech_name": "Edward B.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-05-07",
        "review_rate": 4.8,
        "customer_name": "Brittany Davis",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Larry S.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-09-25",
        "review_rate": 5.0,
        "customer_name": "James Barker",
        "service_type": "Garage Door Off Track",
        "tech_name": "Mark O.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-19",
        "review_rate": 4.9,
        "customer_name": "Tara Mcdonald",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Lance M.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-11-10",
        "review_rate": 4.9,
        "customer_name": "Michael Jacobs",
        "service_type": "Residential Garage Door Services",
        "tech_name": "John G.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-11",
        "review_rate": 4.8,
        "customer_name": "Sandra Estes",
        "service_type": "Garage Door Track Repair",
        "tech_name": "David B.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-03-25",
        "review_rate": 5.0,
        "customer_name": "Kimberly Rodriguez",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Kristin H.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-06-24",
        "review_rate": 4.8,
        "customer_name": "Michael Charles",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Anthony T.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-07-08",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Cline",
        "service_type": "Garage Door Services",
        "tech_name": "Matthew W.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-01-01",
        "review_rate": 4.9,
        "customer_name": "Curtis Clay",
        "service_type": "Garage Door Repair",
        "tech_name": "Lisa M.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-19",
        "review_rate": 4.8,
        "customer_name": "Jennifer Mcmahon",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Elizabeth M.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-07",
        "review_rate": 5.0,
        "customer_name": "Mark Phillips",
        "service_type": "Garage Door Opener",
        "tech_name": "Nicholas E.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-11-10",
        "review_rate": 4.9,
        "customer_name": "Sarah Hayes",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Caroline C.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-23",
        "review_rate": 4.9,
        "customer_name": "Dawn Phillips",
        "service_type": "Garage Door Opener",
        "tech_name": "Stephen W.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-31",
        "review_rate": 4.9,
        "customer_name": "Larry Snyder",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Patrick B.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-02-27",
        "review_rate": 4.8,
        "customer_name": "Jared Winters",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "James B.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-12-10",
        "review_rate": 4.9,
        "customer_name": "Alison Gonzalez",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Ruth W.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-05-28",
        "review_rate": 4.9,
        "customer_name": "Travis Chase",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Sandra B.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-01-11",
        "review_rate": 4.9,
        "customer_name": "Michael Hood",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Ryan P.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-09-07",
        "review_rate": 4.9,
        "customer_name": "Christina Pittman",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Melissa M.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-09-07",
        "review_rate": 4.9,
        "customer_name": "Christina Mcknight",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Shannon W.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-11-03",
        "review_rate": 4.9,
        "customer_name": "Jacqueline Hernandez",
        "service_type": "Garage Door Insulation",
        "tech_name": "Eric M.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-03-07",
        "review_rate": 4.9,
        "customer_name": "Donna Reed",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "George P.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-11-15",
        "review_rate": 5.0,
        "customer_name": "Shannon Johnson",
        "service_type": "Garage Door Opener",
        "tech_name": "Sean M.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-11-25",
        "review_rate": 4.8,
        "customer_name": "Sharon Cruz",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Allison E.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-30",
        "review_rate": 4.9,
        "customer_name": "Ashley Cox",
        "service_type": "Garage Door Opener",
        "tech_name": "Diana M.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-02-29",
        "review_rate": 4.9,
        "customer_name": "Victoria Thomas",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Jeffrey H.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-05-02",
        "review_rate": 5.0,
        "customer_name": "Charles Harris",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Jason W.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-04-17",
        "review_rate": 4.8,
        "customer_name": "Mr. Tanner Case Jr.",
        "service_type": "Garage Door Services",
        "tech_name": "Meghan B.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-14",
        "review_rate": 4.9,
        "customer_name": "Katherine Davis",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Maria M.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-01-30",
        "review_rate": 4.8,
        "customer_name": "Elizabeth Thompson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Randall D.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-05-30",
        "review_rate": 4.9,
        "customer_name": "Gregory Garcia",
        "service_type": "Garage Door Services",
        "tech_name": "Leslie P.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-02-21",
        "review_rate": 4.8,
        "customer_name": "Megan Davidson",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Philip C.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-11-28",
        "review_rate": 5.0,
        "customer_name": "Meredith Lopez",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Jacob M.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-03-03",
        "review_rate": 4.9,
        "customer_name": "Christopher Hall",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Wanda K.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-02-24",
        "review_rate": 4.9,
        "customer_name": "Barry Ramirez",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Diana B.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-05-26",
        "review_rate": 4.9,
        "customer_name": "Katie Lowery",
        "service_type": "Garage Door Repair",
        "tech_name": "Carrie G.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-06-28",
        "review_rate": 4.8,
        "customer_name": "Anthony Wells",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Michelle D.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-07-12",
        "review_rate": 4.9,
        "customer_name": "Michelle Clark",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Dennis P.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-09-24",
        "review_rate": 5.0,
        "customer_name": "Mr. John Schultz",
        "service_type": "Garage Door Opener",
        "tech_name": "Walter W.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-08",
        "review_rate": 5.0,
        "customer_name": "Tamara Williams",
        "service_type": "Garage Door Installation",
        "tech_name": "Michael W.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-06-28",
        "review_rate": 4.9,
        "customer_name": "Fernando Gordon",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Catherine M.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-11-02",
        "review_rate": 4.8,
        "customer_name": "Dr. Michael Harris",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Cynthia C.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-02-09",
        "review_rate": 4.8,
        "customer_name": "Pamela Allen",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Christopher P.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-12-03",
        "review_rate": 4.8,
        "customer_name": "Curtis Roy",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Richard G.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-12-11",
        "review_rate": 4.9,
        "customer_name": "Mrs. Michelle Bishop",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "William K.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-02-29",
        "review_rate": 4.9,
        "customer_name": "Linda Johnson",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Kim C.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-10-22",
        "review_rate": 4.9,
        "customer_name": "Patricia Thomas",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "James H.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-04-27",
        "review_rate": 5.0,
        "customer_name": "Catherine Russell",
        "service_type": "Garage Door Installation",
        "tech_name": "Ryan K.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-11-17",
        "review_rate": 5.0,
        "customer_name": "Lori Marshall",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Darren M.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-01-13",
        "review_rate": 5.0,
        "customer_name": "Nathan Lewis",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Robert T.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-24",
        "review_rate": 4.8,
        "customer_name": "Shelly Gonzales",
        "service_type": "Garage Door Installation",
        "tech_name": "Leah V.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-01-19",
        "review_rate": 4.9,
        "customer_name": "Scott Andrews",
        "service_type": "Garage Door Repair",
        "tech_name": "Andrea P.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-08",
        "review_rate": 4.8,
        "customer_name": "Sarah Simmons",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Janet P.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-01-03",
        "review_rate": 4.9,
        "customer_name": "Katherine Gray",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Beverly B.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-10-13",
        "review_rate": 5.0,
        "customer_name": "Elizabeth Michael",
        "service_type": "Garage Door Off Track",
        "tech_name": "Andrew M.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-12-31",
        "review_rate": 4.9,
        "customer_name": "David Flores",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "John R.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-04-26",
        "review_rate": 4.9,
        "customer_name": "Michelle Holmes",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Carlos M.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-08-09",
        "review_rate": 4.9,
        "customer_name": "Pamela Horton",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Miranda H.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-01-11",
        "review_rate": 4.9,
        "customer_name": "Rebecca Jimenez",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Eric C.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-11-27",
        "review_rate": 4.9,
        "customer_name": "Andrew Carter",
        "service_type": "Garage Door Repair",
        "tech_name": "Jared H.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-12-14",
        "review_rate": 4.8,
        "customer_name": "Nicholas Bell",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Alexis B.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-08-03",
        "review_rate": 4.8,
        "customer_name": "Mrs. Sara Clark MD",
        "service_type": "Garage Door Installation",
        "tech_name": "Christopher T.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-09-20",
        "review_rate": 4.9,
        "customer_name": "Anita Castillo",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Eric H.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-03-20",
        "review_rate": 5.0,
        "customer_name": "Carol Mendez",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Cynthia M.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-13",
        "review_rate": 4.9,
        "customer_name": "Wesley Randall",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Charles C.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-07-15",
        "review_rate": 4.8,
        "customer_name": "Michelle Proctor",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "James E.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-07-16",
        "review_rate": 4.8,
        "customer_name": "Jonathon Salazar",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Catherine D.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-21",
        "review_rate": 4.9,
        "customer_name": "Faith Stout",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Tammy C.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-26",
        "review_rate": 4.8,
        "customer_name": "Jeremiah Benton",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "James R.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-12-03",
        "review_rate": 5.0,
        "customer_name": "Steven Lawson",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Timothy B.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-08-10",
        "review_rate": 4.9,
        "customer_name": "Laura West",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Erica T.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-12-19",
        "review_rate": 4.8,
        "customer_name": "Anthony Cruz",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Jasmine P.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-04-01",
        "review_rate": 5.0,
        "customer_name": "Jessica Burns",
        "service_type": "Garage Door Insulation",
        "tech_name": "Jenna E.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-10-20",
        "review_rate": 5.0,
        "customer_name": "Mark Williams",
        "service_type": "Garage Door Installation",
        "tech_name": "Sharon G.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-02-10",
        "review_rate": 4.9,
        "customer_name": "Tiffany Mack",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Diane H.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-28",
        "review_rate": 4.9,
        "customer_name": "Amy Coffey",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Patricia W.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-07-09",
        "review_rate": 4.9,
        "customer_name": "Jeanne Shannon",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Jonathan S.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-14",
        "review_rate": 5.0,
        "customer_name": "Mr. Sean Thomas DDS",
        "service_type": "Garage Door Repair",
        "tech_name": "Mathew M.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-22",
        "review_rate": 4.9,
        "customer_name": "Amy Matthews",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Michelle M.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-12-15",
        "review_rate": 5.0,
        "customer_name": "Eric Wilson",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Michelle W.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-10-03",
        "review_rate": 5.0,
        "customer_name": "Dr. Michael Dunn DVM",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Gabriella D.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-06-13",
        "review_rate": 4.9,
        "customer_name": "Joshua Martin",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Lisa F.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-26",
        "review_rate": 4.9,
        "customer_name": "Cynthia Irwin",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Cheryl S.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-11-14",
        "review_rate": 4.9,
        "customer_name": "David Harvey",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Catherine W.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-06-27",
        "review_rate": 4.9,
        "customer_name": "Leslie Brock",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Emma B.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-10-23",
        "review_rate": 4.9,
        "customer_name": "Tanya Romero",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Ian M.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-08-16",
        "review_rate": 4.8,
        "customer_name": "Kimberly Ramos",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "James C.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-28",
        "review_rate": 4.8,
        "customer_name": "Thomas Jenkins",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Deborah S.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-08-23",
        "review_rate": 5.0,
        "customer_name": "Frank Miller",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Cory A.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-09-24",
        "review_rate": 4.9,
        "customer_name": "Carla Morales",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Lauren H.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-01-24",
        "review_rate": 4.9,
        "customer_name": "Nathan Carr",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Susan W.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-07-01",
        "review_rate": 4.9,
        "customer_name": "Logan Mcpherson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Wesley A.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-07-07",
        "review_rate": 4.8,
        "customer_name": "Claire Love",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Jacob K.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-02",
        "review_rate": 5.0,
        "customer_name": "Sharon George",
        "service_type": "Garage Door Insulation",
        "tech_name": "Valerie R.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-10-10",
        "review_rate": 5.0,
        "customer_name": "Joshua Klein",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Amber S.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-05-13",
        "review_rate": 4.8,
        "customer_name": "Connie Hunter",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Tony S.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-08-15",
        "review_rate": 4.9,
        "customer_name": "Donald Parker",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Aaron S.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-07-10",
        "review_rate": 4.9,
        "customer_name": "Kimberly Parker",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Matthew S.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-07-14",
        "review_rate": 4.9,
        "customer_name": "Julie Perez",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Cassandra P.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-18",
        "review_rate": 5.0,
        "customer_name": "Alexandra Roman",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Laura T.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-11-09",
        "review_rate": 5.0,
        "customer_name": "Ryan Johnson",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Candace M.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-07-06",
        "review_rate": 4.9,
        "customer_name": "Destiny Webster",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Christina M.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-08-08",
        "review_rate": 5.0,
        "customer_name": "Joe Smith",
        "service_type": "Garage Door Installation",
        "tech_name": "Emily R.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-09-26",
        "review_rate": 5.0,
        "customer_name": "Bryan Ramirez",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Kelly A.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-11-20",
        "review_rate": 4.9,
        "customer_name": "Chris White",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Melinda W.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-25",
        "review_rate": 4.9,
        "customer_name": "Madison Silva",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Karen B.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-12-22",
        "review_rate": 4.9,
        "customer_name": "Carl Green",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Gabrielle W.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-02-26",
        "review_rate": 4.9,
        "customer_name": "Denise Smith",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Vanessa H.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-06-13",
        "review_rate": 4.9,
        "customer_name": "Jessica Jones",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Savannah J.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-02-28",
        "review_rate": 4.8,
        "customer_name": "Kenneth Joseph",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Mark B.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-10-19",
        "review_rate": 5.0,
        "customer_name": "Sheena Ramos",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "William R.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-05-20",
        "review_rate": 4.9,
        "customer_name": "Logan Davis",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Matthew J.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-10-15",
        "review_rate": 4.9,
        "customer_name": "John Bradley",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "James M.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-11-19",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Jenkins",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Dawn C.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-07-09",
        "review_rate": 5.0,
        "customer_name": "Lance Trujillo",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Charlotte C.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-08-13",
        "review_rate": 4.8,
        "customer_name": "Derrick Odonnell",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Mary R.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-06-02",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Henson",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Gina H.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-11-17",
        "review_rate": 4.8,
        "customer_name": "Dr. Richard Lopez",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Rachael W.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-06-16",
        "review_rate": 4.9,
        "customer_name": "Mark Taylor",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Leslie T.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-15",
        "review_rate": 4.9,
        "customer_name": "Kevin Sullivan",
        "service_type": "Garage Door Repair",
        "tech_name": "John V.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-10-27",
        "review_rate": 4.8,
        "customer_name": "Kara Cortez",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "William R.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-10-12",
        "review_rate": 4.8,
        "customer_name": "Bill Velasquez",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Christopher M.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-10-14",
        "review_rate": 4.8,
        "customer_name": "Dennis Williams",
        "service_type": "Garage Door Repair",
        "tech_name": "Carla B.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-12-26",
        "review_rate": 4.8,
        "customer_name": "Luis Rodriguez",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Renee D.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-06-08",
        "review_rate": 4.8,
        "customer_name": "Lauren Sanchez",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Mark C.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-11-14",
        "review_rate": 4.9,
        "customer_name": "Stacy Thompson",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "David G.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-12-15",
        "review_rate": 4.8,
        "customer_name": "Rebecca Harrison",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Jonathan R.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-06",
        "review_rate": 4.8,
        "customer_name": "Mark Luna",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Megan C.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-12-04",
        "review_rate": 5.0,
        "customer_name": "Lauren Johnston",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Lauren L.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-07-12",
        "review_rate": 4.9,
        "customer_name": "Max Jordan",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Brian B.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-06-13",
        "review_rate": 4.8,
        "customer_name": "Stacy Macdonald",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Emily P.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-09-09",
        "review_rate": 4.9,
        "customer_name": "Mary Wells",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Alexander G.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-02-12",
        "review_rate": 5.0,
        "customer_name": "Julia Campbell",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Tracy F.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-12-07",
        "review_rate": 4.8,
        "customer_name": "Regina Lee",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Richard J.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-04-09",
        "review_rate": 4.9,
        "customer_name": "Grant Hayes",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Beth S.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-12-22",
        "review_rate": 4.8,
        "customer_name": "Tracy Walton",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Curtis C.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-17",
        "review_rate": 4.9,
        "customer_name": "Nancy Miller",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Mary W.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-05-22",
        "review_rate": 4.9,
        "customer_name": "Gary Aguirre",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Robert S.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-02-26",
        "review_rate": 4.9,
        "customer_name": "Mrs. Nicole Mcknight",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Tiffany B.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-10-18",
        "review_rate": 4.8,
        "customer_name": "Melissa Price",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Sandra K.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-09",
        "review_rate": 4.9,
        "customer_name": "Brenda Davis",
        "service_type": "Garage Door Installation",
        "tech_name": "Lisa V.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-10-05",
        "review_rate": 4.8,
        "customer_name": "Melissa Johnson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Bryan K.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-07-05",
        "review_rate": 5.0,
        "customer_name": "Dr. Nicholas Koch",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Dominic C.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-08-15",
        "review_rate": 5.0,
        "customer_name": "Rhonda Rogers",
        "service_type": "Garage Door Repair",
        "tech_name": "Lisa F.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-06-23",
        "review_rate": 4.8,
        "customer_name": "David James",
        "service_type": "Garage Door Repair",
        "tech_name": "Kurt C.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-06-29",
        "review_rate": 4.9,
        "customer_name": "Stacey Hancock",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Jennifer C.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-08-28",
        "review_rate": 4.8,
        "customer_name": "Dr. Tonya Daniels MD",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Christina V.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-08-29",
        "review_rate": 4.9,
        "customer_name": "Mary Kaiser",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Michael Y.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-09-25",
        "review_rate": 4.9,
        "customer_name": "Isaiah Garcia",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Johnathan A.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-12-10",
        "review_rate": 5.0,
        "customer_name": "Peter Nelson",
        "service_type": "Garage Door Insulation",
        "tech_name": "Alexa S.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-10-02",
        "review_rate": 4.9,
        "customer_name": "Michael Ortiz",
        "service_type": "Garage Door Insulation",
        "tech_name": "Diane P.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-04-15",
        "review_rate": 5.0,
        "customer_name": "Dr. Roger Watson MD",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Daniel M.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-30",
        "review_rate": 5.0,
        "customer_name": "Erik Evans",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Mary W.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-12-29",
        "review_rate": 5.0,
        "customer_name": "Benjamin Adams",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Alyssa M.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-11-06",
        "review_rate": 5.0,
        "customer_name": "Alyssa Burch",
        "service_type": "Garage Door Installation",
        "tech_name": "Jennifer F.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-01-22",
        "review_rate": 5.0,
        "customer_name": "Larry Allen",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Rachel J.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-21",
        "review_rate": 5.0,
        "customer_name": "Julie Downs",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Holly W.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-11-26",
        "review_rate": 5.0,
        "customer_name": "Derek Gutierrez",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Kelly M.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-06-13",
        "review_rate": 5.0,
        "customer_name": "Martin Lee",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Leslie A.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-11-17",
        "review_rate": 5.0,
        "customer_name": "Jennifer Raymond",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Brian C.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-10-16",
        "review_rate": 4.9,
        "customer_name": "Joshua Campbell",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Kim K.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-11-03",
        "review_rate": 4.9,
        "customer_name": "Lisa Page",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Kevin H.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-06-22",
        "review_rate": 4.9,
        "customer_name": "Brandon Patel",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Dana R.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-11-01",
        "review_rate": 4.9,
        "customer_name": "Marc Garcia",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Jennifer B.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-09-27",
        "review_rate": 4.8,
        "customer_name": "Ryan Garcia",
        "service_type": "Garage Door Services",
        "tech_name": "Benjamin C.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-02-20",
        "review_rate": 4.9,
        "customer_name": "Michael White",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Teresa G.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-06-25",
        "review_rate": 4.8,
        "customer_name": "David Ward",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Janice B.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-06-08",
        "review_rate": 5.0,
        "customer_name": "Christopher Douglas",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Emma C.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-03-11",
        "review_rate": 5.0,
        "customer_name": "Adrian Smith",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Eileen B.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-07-30",
        "review_rate": 5.0,
        "customer_name": "Barry Neal",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Lee R.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-03-03",
        "review_rate": 4.9,
        "customer_name": "Lisa Martinez",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Adam S.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-08-15",
        "review_rate": 5.0,
        "customer_name": "Brian Davis",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Mary W.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-12-25",
        "review_rate": 4.9,
        "customer_name": "Wanda Lewis",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Carol T.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-01-24",
        "review_rate": 5.0,
        "customer_name": "Juan Powell",
        "service_type": "Garage Door Installation",
        "tech_name": "Timothy H.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-09-02",
        "review_rate": 4.9,
        "customer_name": "Vickie Webb",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Michelle W.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-04-20",
        "review_rate": 5.0,
        "customer_name": "Rachel Davidson",
        "service_type": "Garage Door Repair",
        "tech_name": "Adrienne H.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-07-31",
        "review_rate": 4.9,
        "customer_name": "Ashley Williams",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Nicole G.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-06-14",
        "review_rate": 4.8,
        "customer_name": "Madison Mayer",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Timothy H.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-09-01",
        "review_rate": 4.9,
        "customer_name": "Jimmy Castillo",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Tyler M.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-07-11",
        "review_rate": 5.0,
        "customer_name": "Mark Steele",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Isaiah P.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-10-16",
        "review_rate": 4.9,
        "customer_name": "Courtney Miller",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Antonio J.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-02-14",
        "review_rate": 5.0,
        "customer_name": "Evan Marks DDS",
        "service_type": "Garage Door Repair",
        "tech_name": "Alexander W.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-09-08",
        "review_rate": 4.9,
        "customer_name": "James Mcdonald",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Gabriela T.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-04-18",
        "review_rate": 5.0,
        "customer_name": "Linda Townsend",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Mary G.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-12-14",
        "review_rate": 5.0,
        "customer_name": "Cody Garcia",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Natalie B.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-11-15",
        "review_rate": 4.9,
        "customer_name": "Ryan Arellano",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Brandon M.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-09-21",
        "review_rate": 4.8,
        "customer_name": "Sandra Swanson",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Matthew B.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-11-22",
        "review_rate": 5.0,
        "customer_name": "Brent Martinez",
        "service_type": "Garage Door Repair",
        "tech_name": "Nicholas C.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-10-01",
        "review_rate": 5.0,
        "customer_name": "Melanie Dunlap",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Craig H.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-01-04",
        "review_rate": 5.0,
        "customer_name": "James Mcdonald",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Jeanette M.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-11-20",
        "review_rate": 4.9,
        "customer_name": "Andrea Turner",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Ashlee H.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-10-10",
        "review_rate": 4.9,
        "customer_name": "Anthony Thomas",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Alexander M.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-11-28",
        "review_rate": 4.8,
        "customer_name": "Bobby Fernandez",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Jennifer S.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-07-23",
        "review_rate": 4.9,
        "customer_name": "Robert Anderson",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Jose H.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-08-21",
        "review_rate": 4.9,
        "customer_name": "Jason Lang",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Heather A.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-09-22",
        "review_rate": 4.9,
        "customer_name": "Hayden Franklin",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Katherine H.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-04-07",
        "review_rate": 5.0,
        "customer_name": "Michael Guzman",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Diana G.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-03-18",
        "review_rate": 5.0,
        "customer_name": "Virginia Donaldson",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Laura P.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-09-23",
        "review_rate": 5.0,
        "customer_name": "Jason Valdez",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Tracy K.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-07-06",
        "review_rate": 5.0,
        "customer_name": "Michael Griffith",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Steven R.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-11-24",
        "review_rate": 5.0,
        "customer_name": "Michelle Jackson",
        "service_type": "Garage Door Off Track",
        "tech_name": "Ethan M.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-01-18",
        "review_rate": 4.9,
        "customer_name": "Kelsey Baldwin",
        "service_type": "Garage Door Insulation",
        "tech_name": "Lindsey B.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-07-16",
        "review_rate": 5.0,
        "customer_name": "Allison Burton",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Rebecca A.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-12-11",
        "review_rate": 4.9,
        "customer_name": "Katherine Hernandez",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Blake C.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-09-15",
        "review_rate": 4.9,
        "customer_name": "Jessica Hill",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Thomas J.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-09-03",
        "review_rate": 4.8,
        "customer_name": "Rita Anderson",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Douglas S.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-08-14",
        "review_rate": 4.8,
        "customer_name": "Stephen Proctor",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Paul L.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-07-13",
        "review_rate": 4.8,
        "customer_name": "Michael Brooks",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Brian W.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-10-17",
        "review_rate": 4.8,
        "customer_name": "Matthew Ward",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Cheryl B.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-12-13",
        "review_rate": 4.9,
        "customer_name": "Joshua Bartlett",
        "service_type": "Garage Door Insulation",
        "tech_name": "Paul B.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-05-04",
        "review_rate": 5.0,
        "customer_name": "Jacob Scott",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Stephanie J.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-08-03",
        "review_rate": 4.9,
        "customer_name": "Monique Sandoval",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Victoria F.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-11-20",
        "review_rate": 4.9,
        "customer_name": "Karen Eaton",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Mark W.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-07-09",
        "review_rate": 4.8,
        "customer_name": "Robert Sanders",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Martha R.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-01-19",
        "review_rate": 5.0,
        "customer_name": "Carrie Frost",
        "service_type": "Garage Door Repair",
        "tech_name": "Natasha C.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-05-16",
        "review_rate": 4.8,
        "customer_name": "April Rodriguez",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Brittany E.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-21",
        "review_rate": 4.9,
        "customer_name": "Allison Sims",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Anthony D.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-12-02",
        "review_rate": 4.8,
        "customer_name": "Daniel Graham",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Sarah B.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-04-15",
        "review_rate": 5.0,
        "customer_name": "Timothy Contreras",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Katherine S.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-04-05",
        "review_rate": 5.0,
        "customer_name": "Michelle Mccarty",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Michelle J.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-09-26",
        "review_rate": 4.8,
        "customer_name": "Victoria Garcia",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Jennifer B.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-12-20",
        "review_rate": 4.9,
        "customer_name": "Tony Schwartz",
        "service_type": "Garage Door Opener",
        "tech_name": "Jacob R.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-04-17",
        "review_rate": 4.9,
        "customer_name": "Tyler Casey MD",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Julia L.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-07",
        "review_rate": 4.9,
        "customer_name": "Chelsea Larsen",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Amy L.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-01-09",
        "review_rate": 4.9,
        "customer_name": "Samantha Smith",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Cameron B.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-10-29",
        "review_rate": 4.9,
        "customer_name": "Jennifer Blevins",
        "service_type": "Garage Door Track Repair",
        "tech_name": "John J.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-04-18",
        "review_rate": 5.0,
        "customer_name": "Laura Nelson",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Dennis D.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-10-24",
        "review_rate": 4.9,
        "customer_name": "Ann Peterson",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Maurice H.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-01-07",
        "review_rate": 4.8,
        "customer_name": "James Williams",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Madison M.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-03-03",
        "review_rate": 4.9,
        "customer_name": "Tiffany Gilbert",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Natasha H.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-08-21",
        "review_rate": 4.8,
        "customer_name": "Rachel Johnson",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Sharon L.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-03-09",
        "review_rate": 5.0,
        "customer_name": "Douglas Johnson",
        "service_type": "Garage Door Off Track",
        "tech_name": "Ivan R.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-06-03",
        "review_rate": 5.0,
        "customer_name": "Mark Phillips",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Carol C.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-05-27",
        "review_rate": 4.9,
        "customer_name": "Jonathan Jones",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Cameron G.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-20",
        "review_rate": 4.9,
        "customer_name": "Beth Brown",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Sarah J.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-05-10",
        "review_rate": 4.9,
        "customer_name": "Melissa Williams",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Julie L.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-06-03",
        "review_rate": 4.8,
        "customer_name": "Courtney Ingram",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Christopher D.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-04-18",
        "review_rate": 5.0,
        "customer_name": "Ashley Hensley",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Brooke C.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-06-11",
        "review_rate": 4.8,
        "customer_name": "Kyle Jones",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Yvette L.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-03-13",
        "review_rate": 4.9,
        "customer_name": "Melanie Malone",
        "service_type": "Garage Door Opener",
        "tech_name": "Joseph P.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-03-14",
        "review_rate": 5.0,
        "customer_name": "Jennifer Webb",
        "service_type": "Garage Door Off Track",
        "tech_name": "Mark M.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-09-10",
        "review_rate": 4.9,
        "customer_name": "Andre Martinez",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "James M.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-01-07",
        "review_rate": 5.0,
        "customer_name": "Kelly Buckley",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Hannah R.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-06-07",
        "review_rate": 4.8,
        "customer_name": "Stacey Farrell",
        "service_type": "Garage Door Opener",
        "tech_name": "Cynthia V.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-07-23",
        "review_rate": 4.9,
        "customer_name": "Donald Hodges",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Destiny R.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-10-02",
        "review_rate": 4.9,
        "customer_name": "Scott Suarez",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Michael B.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-11-11",
        "review_rate": 4.9,
        "customer_name": "John Chavez",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Michael R.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-12-10",
        "review_rate": 4.8,
        "customer_name": "Juan Mason",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Paula T.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-09-22",
        "review_rate": 4.8,
        "customer_name": "Alicia Hernandez",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Craig S.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-10-31",
        "review_rate": 4.8,
        "customer_name": "Justin Soto",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Gabriel W.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-17",
        "review_rate": 4.9,
        "customer_name": "Diana Robinson",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Theresa M.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-12-02",
        "review_rate": 5.0,
        "customer_name": "Daniel Morgan",
        "service_type": "Garage Door Repair",
        "tech_name": "Shawn L.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-10",
        "review_rate": 4.9,
        "customer_name": "Monique Smith",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Bryan F.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-07-16",
        "review_rate": 4.8,
        "customer_name": "Derrick Chen",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Scott C.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-09-11",
        "review_rate": 4.9,
        "customer_name": "Russell Mclean",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Cynthia A.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-06-29",
        "review_rate": 5.0,
        "customer_name": "April Avila",
        "service_type": "Garage Door Installation",
        "tech_name": "Andrew V.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-03-11",
        "review_rate": 4.8,
        "customer_name": "Phyllis Shaw",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Kenneth H.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-02-10",
        "review_rate": 4.9,
        "customer_name": "Jessica Bond",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Scott H.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-09-27",
        "review_rate": 4.9,
        "customer_name": "John Campos Jr.",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Alejandro L.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-12-17",
        "review_rate": 4.8,
        "customer_name": "Elizabeth Wallace",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Julia W.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-09-14",
        "review_rate": 4.9,
        "customer_name": "Andrew Lewis",
        "service_type": "Garage Door Insulation",
        "tech_name": "David G.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-07-20",
        "review_rate": 5.0,
        "customer_name": "Sharon Jones",
        "service_type": "Garage Door Repair",
        "tech_name": "Rodney R.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-02-25",
        "review_rate": 4.9,
        "customer_name": "Timothy Aguilar",
        "service_type": "Garage Door Insulation",
        "tech_name": "Cindy M.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-07-30",
        "review_rate": 4.9,
        "customer_name": "Ryan Krause",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Robert P.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-02-16",
        "review_rate": 4.9,
        "customer_name": "Christian Chapman",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Brianna C.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-05-02",
        "review_rate": 4.9,
        "customer_name": "Nicole Jones DDS",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Heidi J.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-11-14",
        "review_rate": 4.9,
        "customer_name": "Christopher King",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Carlos A.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-02-06",
        "review_rate": 4.9,
        "customer_name": "Jacqueline Powell",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Seth M.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-12-29",
        "review_rate": 4.8,
        "customer_name": "Kim Howard",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Melissa L.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-01-23",
        "review_rate": 4.8,
        "customer_name": "Justin Chase DDS",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Veronica R.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-04-30",
        "review_rate": 4.9,
        "customer_name": "Alejandro Andrews",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Joshua P.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-05-31",
        "review_rate": 5.0,
        "customer_name": "Jennifer Roy",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Nichole S.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-06-13",
        "review_rate": 4.8,
        "customer_name": "Erik Ferguson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Alicia W.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-03-18",
        "review_rate": 5.0,
        "customer_name": "Christopher Murphy",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Brittany H.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-06-05",
        "review_rate": 4.9,
        "customer_name": "Tracy Garcia",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Michael S.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-25",
        "review_rate": 5.0,
        "customer_name": "Lisa Warren",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Aaron B.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-10-28",
        "review_rate": 4.9,
        "customer_name": "Jordan Stewart",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Melanie H.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-04-08",
        "review_rate": 5.0,
        "customer_name": "David Mcfarland",
        "service_type": "Garage Door Opener",
        "tech_name": "Marilyn T.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-12-01",
        "review_rate": 4.9,
        "customer_name": "Kelsey Ferguson",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Jorge D.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-07-14",
        "review_rate": 4.8,
        "customer_name": "Mark Evans",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Emily S.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-02-03",
        "review_rate": 4.9,
        "customer_name": "Larry Acosta",
        "service_type": "Garage Door Opener",
        "tech_name": "Samantha P.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-05-31",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Miller",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "David B.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-10-07",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Compton",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Mark H.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-05-12",
        "review_rate": 4.8,
        "customer_name": "Kimberly Chandler",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Michelle R.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-09-27",
        "review_rate": 5.0,
        "customer_name": "Ethan Davis",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Stacey G.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-12-15",
        "review_rate": 4.8,
        "customer_name": "Anthony Rivas",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Leslie F.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-04-23",
        "review_rate": 4.9,
        "customer_name": "Mrs. Melissa Carter",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Taylor H.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-19",
        "review_rate": 4.8,
        "customer_name": "Jesse Bates Jr.",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Susan P.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-09-12",
        "review_rate": 4.9,
        "customer_name": "Diana Hernandez",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Jeremy L.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-06-19",
        "review_rate": 4.8,
        "customer_name": "Cynthia Chan",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Jessica J.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-07-05",
        "review_rate": 4.9,
        "customer_name": "Jenna Little",
        "service_type": "Garage Door Repair",
        "tech_name": "Linda R.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-05-04",
        "review_rate": 4.8,
        "customer_name": "Samantha Phelps",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Brett C.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-06-11",
        "review_rate": 4.9,
        "customer_name": "Heather Henderson",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "David J.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-03-07",
        "review_rate": 4.8,
        "customer_name": "Ashley Williams",
        "service_type": "Garage Door Insulation",
        "tech_name": "Terry N.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-05-16",
        "review_rate": 5.0,
        "customer_name": "Kyle Bell",
        "service_type": "Garage Door Installation",
        "tech_name": "Laura L.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-07-28",
        "review_rate": 5.0,
        "customer_name": "Cynthia Bradley",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Kevin R.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-09-10",
        "review_rate": 4.9,
        "customer_name": "Margaret Williams",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Jessica K.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-09-15",
        "review_rate": 4.9,
        "customer_name": "Mark Black",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Douglas Q.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-11-01",
        "review_rate": 4.9,
        "customer_name": "Sergio Smith PhD",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Jennifer W.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-05-29",
        "review_rate": 4.8,
        "customer_name": "Gabriel Jackson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Charles M.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-09-29",
        "review_rate": 5.0,
        "customer_name": "Isaac Herrera",
        "service_type": "Garage Door Off Track",
        "tech_name": "Chelsea V.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-12-26",
        "review_rate": 5.0,
        "customer_name": "Julia Rodriguez",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Harold D.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-03-20",
        "review_rate": 4.9,
        "customer_name": "Kyle Soto",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Penny C.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-07-24",
        "review_rate": 4.9,
        "customer_name": "Jason Young",
        "service_type": "Garage Door Services",
        "tech_name": "Jessica W.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-11-30",
        "review_rate": 4.8,
        "customer_name": "Cameron Thomas",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Erin S.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-04-04",
        "review_rate": 4.8,
        "customer_name": "Chelsea Taylor",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Sarah H.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-08-31",
        "review_rate": 5.0,
        "customer_name": "Brittany Martin",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Briana R.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-07-04",
        "review_rate": 4.9,
        "customer_name": "Lindsay James",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "David B.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-01-14",
        "review_rate": 4.9,
        "customer_name": "Johnny Sexton",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Marissa P.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-07-14",
        "review_rate": 4.9,
        "customer_name": "Melissa Miller",
        "service_type": "Garage Door Off Track",
        "tech_name": "Richard C.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-01-17",
        "review_rate": 4.9,
        "customer_name": "Larry Hayes",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Karen S.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-07-27",
        "review_rate": 4.9,
        "customer_name": "Bradley Payne",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Katelyn L.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-05-16",
        "review_rate": 4.9,
        "customer_name": "Raymond Mejia",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Sara A.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-07-03",
        "review_rate": 5.0,
        "customer_name": "Marc Martin",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Jacob B.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-12",
        "review_rate": 4.9,
        "customer_name": "Mr. Keith Montoya",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Matthew O.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-04-04",
        "review_rate": 4.9,
        "customer_name": "Marc Jenkins DVM",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Lindsey C.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-03-20",
        "review_rate": 4.8,
        "customer_name": "John Schmidt",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Andrew S.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-08",
        "review_rate": 4.9,
        "customer_name": "Cassandra Wilkinson",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Timothy P.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-08-15",
        "review_rate": 4.8,
        "customer_name": "Jennifer Miller",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Mark T.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-13",
        "review_rate": 4.9,
        "customer_name": "Peggy Edwards",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Joseph S.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-04-11",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Hull",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Sabrina R.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-23",
        "review_rate": 4.8,
        "customer_name": "Joshua Osborne",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Elizabeth D.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-29",
        "review_rate": 4.8,
        "customer_name": "Michelle Newton",
        "service_type": "Garage Door Off Track",
        "tech_name": "Cameron R.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-01-23",
        "review_rate": 5.0,
        "customer_name": "Joshua Park",
        "service_type": "Garage Door Insulation",
        "tech_name": "Kevin K.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-01-26",
        "review_rate": 4.9,
        "customer_name": "Margaret Nguyen",
        "service_type": "Garage Door Off Track",
        "tech_name": "Warren M.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-01-29",
        "review_rate": 4.8,
        "customer_name": "Patty Malone",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Melissa S.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-07-06",
        "review_rate": 4.9,
        "customer_name": "Gregory Mccullough",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Erin W.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-09-15",
        "review_rate": 4.8,
        "customer_name": "Karen Foster",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Rhonda E.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-17",
        "review_rate": 4.9,
        "customer_name": "Benjamin Spears",
        "service_type": "Garage Door Opener",
        "tech_name": "Courtney R.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-04-12",
        "review_rate": 4.9,
        "customer_name": "Maria Wilson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Jonathan P.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-02-01",
        "review_rate": 4.9,
        "customer_name": "Brian Curtis",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Sharon F.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-05-31",
        "review_rate": 4.8,
        "customer_name": "Andrew Webster",
        "service_type": "Garage Door Opener",
        "tech_name": "Michael T.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-12-10",
        "review_rate": 4.9,
        "customer_name": "Stephanie Howell",
        "service_type": "Garage Door Off Track",
        "tech_name": "Garrett F.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-09-16",
        "review_rate": 4.9,
        "customer_name": "Brian Williamson",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Corey J.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-12-06",
        "review_rate": 4.8,
        "customer_name": "Shawna Robinson",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Patricia C.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-05-31",
        "review_rate": 5.0,
        "customer_name": "Chase Sandoval",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Kelly G.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-05-17",
        "review_rate": 5.0,
        "customer_name": "Mr. Matthew Calhoun",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Shane K.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-08-17",
        "review_rate": 5.0,
        "customer_name": "Michelle Taylor",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Jeffery P.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-03-27",
        "review_rate": 4.9,
        "customer_name": "Nathaniel Daniels",
        "service_type": "Garage Door Installation",
        "tech_name": "Krystal P.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-25",
        "review_rate": 4.9,
        "customer_name": "Connor Gomez",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Jessica C.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-09-09",
        "review_rate": 4.9,
        "customer_name": "Natasha Cordova",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Johnathan H.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-06",
        "review_rate": 5.0,
        "customer_name": "Daniel Davidson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Drew R.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-11-08",
        "review_rate": 4.9,
        "customer_name": "Luis Collins",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Sandra P.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-08-24",
        "review_rate": 4.9,
        "customer_name": "Jessica Fowler",
        "service_type": "Garage Door Installation",
        "tech_name": "Rodney G.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-12-05",
        "review_rate": 4.9,
        "customer_name": "Jordan Evans",
        "service_type": "Garage Door Insulation",
        "tech_name": "Steven S.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-13",
        "review_rate": 4.9,
        "customer_name": "Stephanie Perkins",
        "service_type": "Garage Door Opener",
        "tech_name": "Julie M.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-05-23",
        "review_rate": 5.0,
        "customer_name": "Pamela Jones",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Brenda C.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-07-29",
        "review_rate": 4.8,
        "customer_name": "Edward Williams",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Megan B.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-13",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Collins",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Isaac W.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-09-16",
        "review_rate": 4.9,
        "customer_name": "Dr. Stephanie Quinn",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Joshua M.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-21",
        "review_rate": 4.9,
        "customer_name": "Joseph Torres",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Stephanie W.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-10-14",
        "review_rate": 4.9,
        "customer_name": "Rachel Mitchell",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Jonathan F.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-10-25",
        "review_rate": 4.8,
        "customer_name": "Ronald House",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Glen M.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-09-20",
        "review_rate": 4.9,
        "customer_name": "Raven Rose",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Dale R.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-19",
        "review_rate": 5.0,
        "customer_name": "Suzanne Allen",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Pamela B.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-06-25",
        "review_rate": 5.0,
        "customer_name": "Kevin Lopez",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Michelle A.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-11",
        "review_rate": 4.9,
        "customer_name": "Stanley Cunningham",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Johnny T.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-11",
        "review_rate": 4.9,
        "customer_name": "Cassidy Baker",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Gabriel N.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-07-08",
        "review_rate": 4.9,
        "customer_name": "Amanda Schmitt",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Bruce W.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-05-10",
        "review_rate": 4.9,
        "customer_name": "Christopher Powell",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Stephen M.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-08-24",
        "review_rate": 4.9,
        "customer_name": "Jeffery White",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Catherine G.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-08-10",
        "review_rate": 5.0,
        "customer_name": "Sara Wright",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Arthur A.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-06-12",
        "review_rate": 4.9,
        "customer_name": "Kristin Torres",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Mary G.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-09-06",
        "review_rate": 4.9,
        "customer_name": "Matthew Fletcher",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Brittany C.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-06-22",
        "review_rate": 5.0,
        "customer_name": "Sara Thompson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Richard C.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-08-03",
        "review_rate": 4.8,
        "customer_name": "Timothy Quinn",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Emily J.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-12-20",
        "review_rate": 5.0,
        "customer_name": "Kathy Garcia",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Juan B.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-06-15",
        "review_rate": 4.9,
        "customer_name": "Sarah Thomas",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Melanie J.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-10-29",
        "review_rate": 4.8,
        "customer_name": "Mark Baird",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Kyle P.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-09-06",
        "review_rate": 4.9,
        "customer_name": "Christopher Walsh",
        "service_type": "Garage Door Installation",
        "tech_name": "Linda D.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-07-01",
        "review_rate": 4.8,
        "customer_name": "Jeffrey Thomas",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Jacob G.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-10-20",
        "review_rate": 5.0,
        "customer_name": "Stephanie Adams",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "James P.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-07-02",
        "review_rate": 4.9,
        "customer_name": "Amanda Murphy",
        "service_type": "Garage Door Insulation",
        "tech_name": "Michael R.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-31",
        "review_rate": 4.8,
        "customer_name": "William Hudson",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Christopher D.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-05-18",
        "review_rate": 5.0,
        "customer_name": "Heather Chen",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Autumn L.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-02-20",
        "review_rate": 4.8,
        "customer_name": "Angela Ross",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Kristen W.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-10-27",
        "review_rate": 4.8,
        "customer_name": "Christopher Byrd",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Autumn D.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-07-31",
        "review_rate": 4.9,
        "customer_name": "Leonard Rojas",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Erin A.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-10",
        "review_rate": 4.9,
        "customer_name": "Mrs. Rhonda Small",
        "service_type": "Garage Door Services",
        "tech_name": "Rhonda M.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-07-26",
        "review_rate": 4.9,
        "customer_name": "Sarah Fisher",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Kathryn B.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-18",
        "review_rate": 4.9,
        "customer_name": "Leah Gross",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Cameron T.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-06-02",
        "review_rate": 4.9,
        "customer_name": "Angela Goodwin MD",
        "service_type": "Garage Door Off Track",
        "tech_name": "Donald C.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-10-09",
        "review_rate": 5.0,
        "customer_name": "Amanda Gibbs",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Thomas L.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-08-31",
        "review_rate": 4.9,
        "customer_name": "David Hughes",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Joshua F.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-07-21",
        "review_rate": 5.0,
        "customer_name": "Laura Marks",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Crystal B.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-08-27",
        "review_rate": 4.8,
        "customer_name": "Lindsay Oliver",
        "service_type": "Garage Door Insulation",
        "tech_name": "Whitney F.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-12-03",
        "review_rate": 4.9,
        "customer_name": "Robert Nelson",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Felicia B.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-23",
        "review_rate": 4.9,
        "customer_name": "Ashley Diaz",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Kaitlyn F.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-04",
        "review_rate": 5.0,
        "customer_name": "Shane Tucker",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Brenda H.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-09-16",
        "review_rate": 4.9,
        "customer_name": "Corey Owens",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Brandon B.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-30",
        "review_rate": 4.9,
        "customer_name": "William Page",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Lauren M.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-10-18",
        "review_rate": 4.9,
        "customer_name": "Charles Davis",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Ricky B.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-05",
        "review_rate": 5.0,
        "customer_name": "Anna Lewis",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Christopher T.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-16",
        "review_rate": 4.9,
        "customer_name": "Kaitlyn Hardin",
        "service_type": "Garage Door Installation",
        "tech_name": "Henry R.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-27",
        "review_rate": 5.0,
        "customer_name": "Jeremy Miller",
        "service_type": "Garage Door Services",
        "tech_name": "Anthony F.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-06-29",
        "review_rate": 5.0,
        "customer_name": "Christina Ball",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Jeffrey M.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-11-16",
        "review_rate": 4.8,
        "customer_name": "Tara Cummings",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Kristine P.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-02-17",
        "review_rate": 4.9,
        "customer_name": "Jason Jackson",
        "service_type": "Garage Door Installation",
        "tech_name": "Carla J.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-08-13",
        "review_rate": 4.9,
        "customer_name": "Joseph Allison",
        "service_type": "Garage Door Installation",
        "tech_name": "Timothy F.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-05-02",
        "review_rate": 4.9,
        "customer_name": "Joseph Serrano",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "James D.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-06-13",
        "review_rate": 4.9,
        "customer_name": "John Graham",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Larry P.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-28",
        "review_rate": 5.0,
        "customer_name": "Warren Dixon",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Christopher G.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-09-29",
        "review_rate": 4.9,
        "customer_name": "Miranda Owens",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Haley Y.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-10",
        "review_rate": 4.8,
        "customer_name": "Benjamin Garcia",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Alexandra B.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-11-22",
        "review_rate": 5.0,
        "customer_name": "Mariah Wood",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Shannon C.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-10-08",
        "review_rate": 4.9,
        "customer_name": "James Dixon",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Frank M.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-19",
        "review_rate": 5.0,
        "customer_name": "Jose Ramirez",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Bradley M.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-22",
        "review_rate": 5.0,
        "customer_name": "Thomas Chan",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Heather C.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-12-16",
        "review_rate": 4.9,
        "customer_name": "Taylor Brown",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Kyle W.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-10-06",
        "review_rate": 4.9,
        "customer_name": "Jeffery Burton",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Joseph H.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-10-20",
        "review_rate": 5.0,
        "customer_name": "Derek Mcintosh",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Denise R.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-10-02",
        "review_rate": 4.9,
        "customer_name": "Jessica Wong",
        "service_type": "Garage Door Services",
        "tech_name": "Victor S.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-05-26",
        "review_rate": 4.9,
        "customer_name": "Matthew Hunt",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Bradley S.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-10",
        "review_rate": 4.9,
        "customer_name": "Kristine Mitchell",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Jenny W.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-10-15",
        "review_rate": 5.0,
        "customer_name": "Richard Cruz",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Adam T.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-01",
        "review_rate": 4.9,
        "customer_name": "Teresa Brown",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Brian J.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-18",
        "review_rate": 4.9,
        "customer_name": "Susan Wells",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Kelly M.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-09-24",
        "review_rate": 4.9,
        "customer_name": "Cheryl Perez",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Jose L.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-09-06",
        "review_rate": 5.0,
        "customer_name": "Kimberly Ward",
        "service_type": "Garage Door Opener",
        "tech_name": "Kimberly G.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-06-18",
        "review_rate": 4.8,
        "customer_name": "Isaiah Rice",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Matthew F.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-07",
        "review_rate": 4.9,
        "customer_name": "William Lozano",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Francisco B.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-18",
        "review_rate": 5.0,
        "customer_name": "Christina Cunningham",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Richard D.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-11-25",
        "review_rate": 4.9,
        "customer_name": "Brandon Green",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Grace W.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-13",
        "review_rate": 5.0,
        "customer_name": "Jeremiah Robinson",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Stanley M.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-06-18",
        "review_rate": 5.0,
        "customer_name": "Erin Park",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Marilyn F.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-01-08",
        "review_rate": 4.8,
        "customer_name": "Brent Cook",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Samuel R.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-11",
        "review_rate": 5.0,
        "customer_name": "Logan Perez",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Michelle H.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-09-30",
        "review_rate": 4.9,
        "customer_name": "Melanie Monroe",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Jessica L.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-10-25",
        "review_rate": 4.8,
        "customer_name": "Todd Farrell",
        "service_type": "Custom Garage Door Design",
        "tech_name": "David R.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-22",
        "review_rate": 4.9,
        "customer_name": "Terry Jones",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Shaun M.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-11-29",
        "review_rate": 4.9,
        "customer_name": "Christina Simmons",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Dana L.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-15",
        "review_rate": 5.0,
        "customer_name": "Deborah Maldonado",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Jose B.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-15",
        "review_rate": 4.9,
        "customer_name": "Melanie Burke",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Jenna D.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-28",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Johnson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Brian I.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-12",
        "review_rate": 4.8,
        "customer_name": "Carol Mcdaniel",
        "service_type": "Garage Door Off Track",
        "tech_name": "Daniel B.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-11-09",
        "review_rate": 4.9,
        "customer_name": "Abigail Hogan",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Jessica R.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-09-02",
        "review_rate": 5.0,
        "customer_name": "Ann Smith",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Jason R.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-01-11",
        "review_rate": 4.8,
        "customer_name": "Janice Nelson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Debbie L.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-12-11",
        "review_rate": 4.9,
        "customer_name": "Michael Hunt",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Lisa R.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-01-11",
        "review_rate": 5.0,
        "customer_name": "Tyler Coleman",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Linda S.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-12-18",
        "review_rate": 4.9,
        "customer_name": "Timothy Booth",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Wendy N.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-12",
        "review_rate": 4.9,
        "customer_name": "Leah Allen",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Debra V.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-12-13",
        "review_rate": 5.0,
        "customer_name": "Corey Gonzalez",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Andrea W.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-24",
        "review_rate": 4.9,
        "customer_name": "Sean Barnes",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Jennifer J.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-10-17",
        "review_rate": 4.8,
        "customer_name": "Brenda Higgins",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Valerie C.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-09",
        "review_rate": 5.0,
        "customer_name": "Roger Clark",
        "service_type": "Garage Door Installation",
        "tech_name": "Bryan J.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-04-04",
        "review_rate": 5.0,
        "customer_name": "James Rodriguez",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Sonia T.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-05-10",
        "review_rate": 4.8,
        "customer_name": "Tara Stuart",
        "service_type": "Garage Door Off Track",
        "tech_name": "Allen R.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-05-10",
        "review_rate": 4.9,
        "customer_name": "Paula Rocha",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Stephanie T.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-09-08",
        "review_rate": 4.9,
        "customer_name": "Tammy Norton",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Gary B.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-06-01",
        "review_rate": 4.9,
        "customer_name": "Patrick Bennett",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Ronald R.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-08-04",
        "review_rate": 4.8,
        "customer_name": "Christopher White",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Melissa G.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-08-20",
        "review_rate": 5.0,
        "customer_name": "James Farrell",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Kelly C.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-06",
        "review_rate": 4.8,
        "customer_name": "Jenna Turner",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Diana W.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-15",
        "review_rate": 5.0,
        "customer_name": "Nicole Green",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Mckenzie C.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-23",
        "review_rate": 4.9,
        "customer_name": "Lisa Barrett",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Jessica G.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-09-18",
        "review_rate": 4.9,
        "customer_name": "Laura Jackson",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Diana M.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-06-22",
        "review_rate": 5.0,
        "customer_name": "Joseph Bautista",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Martin M.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-03-03",
        "review_rate": 4.9,
        "customer_name": "Jason Osborn",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Carol G.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-06-08",
        "review_rate": 4.9,
        "customer_name": "Stephanie Schneider",
        "service_type": "Garage Door Services",
        "tech_name": "Erin S.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-20",
        "review_rate": 4.9,
        "customer_name": "Joseph Berry",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Ryan R.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-11",
        "review_rate": 4.9,
        "customer_name": "April Evans",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "William H.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-01-08",
        "review_rate": 4.9,
        "customer_name": "Gabriel Lee",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Jamie J.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-06-17",
        "review_rate": 5.0,
        "customer_name": "James Townsend",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Jennifer K.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-11-28",
        "review_rate": 4.8,
        "customer_name": "Megan Johnson",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Kelly H.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-04-24",
        "review_rate": 4.8,
        "customer_name": "Jason Macdonald",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Laura A.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-06-23",
        "review_rate": 4.9,
        "customer_name": "Jason Alexander",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Seth G.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-02",
        "review_rate": 4.8,
        "customer_name": "Vanessa Smith",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Brandon P.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-03-07",
        "review_rate": 4.8,
        "customer_name": "Dr. Amanda Jimenez",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Joshua W.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-11-22",
        "review_rate": 5.0,
        "customer_name": "Deborah Thomas",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Brian O.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-08-08",
        "review_rate": 5.0,
        "customer_name": "Mrs. Jessica Armstrong",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Barry S.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-01-24",
        "review_rate": 4.9,
        "customer_name": "Grace Davis",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Virginia P.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-02-02",
        "review_rate": 5.0,
        "customer_name": "George Clayton",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Barbara P.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-01-18",
        "review_rate": 4.8,
        "customer_name": "Noah Hopkins",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Jillian C.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-02-26",
        "review_rate": 4.9,
        "customer_name": "Jerry Moore",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Lori G.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-09-18",
        "review_rate": 4.9,
        "customer_name": "Jason Newman",
        "service_type": "Garage Door Services",
        "tech_name": "James T.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-01-16",
        "review_rate": 4.9,
        "customer_name": "Lauren Collins",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Keith V.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-06",
        "review_rate": 4.9,
        "customer_name": "Amanda Barton",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Andrew W.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-11-09",
        "review_rate": 4.8,
        "customer_name": "Anthony Boyd",
        "service_type": "Garage Door Opener",
        "tech_name": "Tracy L.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-02",
        "review_rate": 4.9,
        "customer_name": "Bradley Howell",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Andrew T.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-05-02",
        "review_rate": 4.9,
        "customer_name": "Amanda Larsen",
        "service_type": "Garage Door Insulation",
        "tech_name": "Elizabeth H.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-06-17",
        "review_rate": 5.0,
        "customer_name": "Rebecca Smith",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Timothy J.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-09-04",
        "review_rate": 4.9,
        "customer_name": "William Merritt",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Sabrina H.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-05-08",
        "review_rate": 4.9,
        "customer_name": "William Fuller",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Maria S.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-03-05",
        "review_rate": 4.9,
        "customer_name": "Troy Taylor",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Lorraine H.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-27",
        "review_rate": 5.0,
        "customer_name": "Sue Alvarez",
        "service_type": "Garage Door Insulation",
        "tech_name": "David M.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-09-04",
        "review_rate": 4.8,
        "customer_name": "Connie Smith",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Robert S.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-05-08",
        "review_rate": 4.9,
        "customer_name": "Maria Turner",
        "service_type": "Garage Door Track Repair",
        "tech_name": "John F.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-12-08",
        "review_rate": 4.9,
        "customer_name": "Shelly Hill",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Ryan E.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-03-31",
        "review_rate": 5.0,
        "customer_name": "Dominique Paul",
        "service_type": "Garage Door Off Track",
        "tech_name": "Michaela P.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-04-28",
        "review_rate": 4.8,
        "customer_name": "Amanda Graham",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Jerry L.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-13",
        "review_rate": 4.8,
        "customer_name": "Molly Doyle",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "David K.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-03-08",
        "review_rate": 4.9,
        "customer_name": "Brady Burke",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Frederick M.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-01-12",
        "review_rate": 4.9,
        "customer_name": "Julia Mccoy",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Jeremiah S.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-01-15",
        "review_rate": 4.8,
        "customer_name": "James Smith",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Michelle F.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-09-19",
        "review_rate": 4.9,
        "customer_name": "Hector Meza",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Bryce R.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-12-18",
        "review_rate": 4.9,
        "customer_name": "Courtney Davis",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Jeffrey S.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-06",
        "review_rate": 5.0,
        "customer_name": "Destiny Martin",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Jennifer T.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-07-30",
        "review_rate": 4.8,
        "customer_name": "Sarah Hester",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Michael P.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-10-23",
        "review_rate": 4.9,
        "customer_name": "Troy Murray",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Randy A.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-01-02",
        "review_rate": 5.0,
        "customer_name": "Grace Lewis",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Regina M.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-05-26",
        "review_rate": 4.9,
        "customer_name": "Phillip Hahn",
        "service_type": "Garage Door Repair",
        "tech_name": "Beverly Z.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-12-07",
        "review_rate": 4.8,
        "customer_name": "Chris Ray",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Jason S.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-04-02",
        "review_rate": 4.8,
        "customer_name": "Ashley Wallace",
        "service_type": "Garage Door Off Track",
        "tech_name": "Tammy S.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-07-12",
        "review_rate": 4.8,
        "customer_name": "Michele Jones",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Emily W.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-06-18",
        "review_rate": 4.9,
        "customer_name": "Sarah Dickson",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Nicole G.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-06-20",
        "review_rate": 4.9,
        "customer_name": "Heather Rogers",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Jimmy S.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-05-09",
        "review_rate": 5.0,
        "customer_name": "Hunter James",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Brianna C.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-04-25",
        "review_rate": 5.0,
        "customer_name": "Lisa Stone",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "John H.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-09-20",
        "review_rate": 5.0,
        "customer_name": "Stacy Turner",
        "service_type": "Garage Door Maintenance",
        "tech_name": "James W.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-06-05",
        "review_rate": 4.8,
        "customer_name": "Andrea Leonard",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Joshua T.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-12-06",
        "review_rate": 4.8,
        "customer_name": "Angela Gregory",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Rickey S.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-01-24",
        "review_rate": 5.0,
        "customer_name": "Danielle Merritt",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Kenneth S.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-07-30",
        "review_rate": 5.0,
        "customer_name": "Anthony Long",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Amy R.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-04-16",
        "review_rate": 4.8,
        "customer_name": "Jessica Ruiz",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Robert B.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-09-23",
        "review_rate": 4.8,
        "customer_name": "Michelle Mccarthy",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "John B.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-12-27",
        "review_rate": 4.8,
        "customer_name": "Aaron Russell",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Brandon C.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-04-29",
        "review_rate": 4.8,
        "customer_name": "Linda Cohen",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Matthew E.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-09-30",
        "review_rate": 4.9,
        "customer_name": "Sharon Ferguson",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Deborah B.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-03-31",
        "review_rate": 4.8,
        "customer_name": "Amy Lloyd",
        "service_type": "Garage Door Opener",
        "tech_name": "Kimberly Y.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-06-29",
        "review_rate": 4.9,
        "customer_name": "Jerry Day",
        "service_type": "Garage Door Off Track",
        "tech_name": "Theresa P.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-12-21",
        "review_rate": 4.9,
        "customer_name": "Debra Wheeler",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Anthony W.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-04-08",
        "review_rate": 4.9,
        "customer_name": "Hannah Riggs",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "David S.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-04-16",
        "review_rate": 4.9,
        "customer_name": "Tony Williams",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Michelle C.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-06-01",
        "review_rate": 5.0,
        "customer_name": "Susan Miles",
        "service_type": "Garage Door Repair",
        "tech_name": "Annette A.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-12-05",
        "review_rate": 5.0,
        "customer_name": "Brandon Sherman",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Matthew W.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-05-22",
        "review_rate": 4.9,
        "customer_name": "John Burke",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Scott H.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-04-04",
        "review_rate": 4.9,
        "customer_name": "Lauren Boyd",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Terri C.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-11-17",
        "review_rate": 4.8,
        "customer_name": "Lauren Francis",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Carolyn F.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-27",
        "review_rate": 4.9,
        "customer_name": "Amy Harper",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Susan C.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-12-06",
        "review_rate": 4.9,
        "customer_name": "Mr. William Ellis MD",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Scott C.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-06-20",
        "review_rate": 5.0,
        "customer_name": "Robin Miller",
        "service_type": "Garage Door Installation",
        "tech_name": "Haley G.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-02-26",
        "review_rate": 4.9,
        "customer_name": "Shawn Thomas",
        "service_type": "Garage Door Repair",
        "tech_name": "John C.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-10-10",
        "review_rate": 4.9,
        "customer_name": "Daniel Evans",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Sandra L.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-06-24",
        "review_rate": 4.9,
        "customer_name": "Samantha Benson",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Brandi H.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-08",
        "review_rate": 4.9,
        "customer_name": "Cindy Garrett",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Michael T.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-11-10",
        "review_rate": 4.9,
        "customer_name": "Paul Stephens",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Robert C.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-03-14",
        "review_rate": 5.0,
        "customer_name": "Kathryn Vargas",
        "service_type": "Garage Door Repair",
        "tech_name": "Jeremiah S.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-01-13",
        "review_rate": 4.8,
        "customer_name": "Robert Austin",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Joseph B.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-02-12",
        "review_rate": 4.9,
        "customer_name": "Dawn Soto",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Nicholas H.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-19",
        "review_rate": 4.9,
        "customer_name": "Bradley Price",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Anthony W.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-04-03",
        "review_rate": 5.0,
        "customer_name": "Andrew Sherman",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Jordan M.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-11-29",
        "review_rate": 4.8,
        "customer_name": "Philip Saunders",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Patricia G.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-09-30",
        "review_rate": 4.8,
        "customer_name": "Patrick Mclaughlin",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Shane H.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-12-30",
        "review_rate": 4.8,
        "customer_name": "Ryan Townsend",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Patrick C.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-06-09",
        "review_rate": 4.8,
        "customer_name": "Emily Smith",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Samantha P.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-11-21",
        "review_rate": 5.0,
        "customer_name": "Tyrone Bender",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Robin M.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-02-21",
        "review_rate": 5.0,
        "customer_name": "Rebecca Steele",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Robert M.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-03-03",
        "review_rate": 4.9,
        "customer_name": "Bradley Schroeder",
        "service_type": "Garage Door Insulation",
        "tech_name": "Blake S.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-05-25",
        "review_rate": 4.8,
        "customer_name": "Cathy Vega",
        "service_type": "Garage Door Opener",
        "tech_name": "Samantha A.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-09-20",
        "review_rate": 4.9,
        "customer_name": "Jennifer Compton",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "David F.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-09-18",
        "review_rate": 5.0,
        "customer_name": "Stephanie Johnson DDS",
        "service_type": "Garage Door Off Track",
        "tech_name": "Erik B.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-07-24",
        "review_rate": 5.0,
        "customer_name": "Darren Miller",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Zachary D.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-04-08",
        "review_rate": 4.8,
        "customer_name": "Kelly Brown",
        "service_type": "Garage Door Off Track",
        "tech_name": "James R.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-10-17",
        "review_rate": 4.9,
        "customer_name": "Jasmine Bennett",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Jessica H.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-11-21",
        "review_rate": 4.8,
        "customer_name": "Lauren Johnson",
        "service_type": "Garage Door Insulation",
        "tech_name": "Steven A.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-09-09",
        "review_rate": 4.9,
        "customer_name": "David Hunt",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Mark H.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-07-01",
        "review_rate": 5.0,
        "customer_name": "Ms. Amber Torres",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Michelle J.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-12-11",
        "review_rate": 4.8,
        "customer_name": "Jeremy Simmons MD",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Shelly N.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-12-22",
        "review_rate": 4.9,
        "customer_name": "Monica Armstrong",
        "service_type": "Garage Door Repair",
        "tech_name": "Lee S.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-04-17",
        "review_rate": 4.9,
        "customer_name": "Joseph Tran",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Timothy M.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-02-20",
        "review_rate": 5.0,
        "customer_name": "Zachary Sanders",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Wayne K.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-12-05",
        "review_rate": 4.9,
        "customer_name": "Ana Smith",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Catherine J.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-01-11",
        "review_rate": 5.0,
        "customer_name": "Charles Lambert",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Trevor W.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-12-05",
        "review_rate": 4.8,
        "customer_name": "Michael Mcconnell",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Dillon J.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-11-27",
        "review_rate": 4.9,
        "customer_name": "Mr. Jeffrey Phillips",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Aimee M.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-12-31",
        "review_rate": 5.0,
        "customer_name": "Christopher Sanders",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Michael B.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-01-21",
        "review_rate": 4.9,
        "customer_name": "Nancy Clark",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Tyler M.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-03-15",
        "review_rate": 4.8,
        "customer_name": "Jonathan Holden",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Catherine D.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-05-15",
        "review_rate": 5.0,
        "customer_name": "Nicholas Mccoy",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Carla G.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-05-25",
        "review_rate": 4.8,
        "customer_name": "Ann Collins",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Heather W.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-06-10",
        "review_rate": 4.9,
        "customer_name": "Scott Cline",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Timothy T.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-05-20",
        "review_rate": 5.0,
        "customer_name": "Logan Myers",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Jeffrey W.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-06-02",
        "review_rate": 4.9,
        "customer_name": "Joseph Newman",
        "service_type": "Garage Door Services",
        "tech_name": "Sean S.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-10-11",
        "review_rate": 4.9,
        "customer_name": "Stephanie Weber",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Daniel M.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-05-30",
        "review_rate": 4.8,
        "customer_name": "Gary Snyder",
        "service_type": "Garage Door Opener",
        "tech_name": "Thomas H.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-01-20",
        "review_rate": 5.0,
        "customer_name": "Charles Mclaughlin",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Miranda M.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-01-01",
        "review_rate": 5.0,
        "customer_name": "Timothy Beasley",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Denise P.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-01-11",
        "review_rate": 4.8,
        "customer_name": "Randall Cook",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Amy B.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-11-17",
        "review_rate": 4.8,
        "customer_name": "Darren Johnson",
        "service_type": "Garage Door Off Track",
        "tech_name": "Jacob D.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-09-24",
        "review_rate": 4.8,
        "customer_name": "Anna Moore",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Joanne G.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-05-08",
        "review_rate": 4.9,
        "customer_name": "Beth Dougherty",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Mark E.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-01-23",
        "review_rate": 5.0,
        "customer_name": "Jorge Ellison",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Penny W.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-10-24",
        "review_rate": 4.8,
        "customer_name": "Laurie Davis MD",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Arthur S.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-01-11",
        "review_rate": 4.9,
        "customer_name": "Patricia Lewis",
        "service_type": "Garage Door Off Track",
        "tech_name": "Alexandra B.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-07-01",
        "review_rate": 4.9,
        "customer_name": "Timothy White",
        "service_type": "Garage Door Off Track",
        "tech_name": "Ronald A.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-11-05",
        "review_rate": 4.9,
        "customer_name": "Jose Jones",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Tamara H.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-01-26",
        "review_rate": 5.0,
        "customer_name": "Melissa Bennett",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Michael P.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-04-29",
        "review_rate": 5.0,
        "customer_name": "Christine Contreras",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Wendy P.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-07-05",
        "review_rate": 4.9,
        "customer_name": "Michael Fernandez",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Heather B.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-05-31",
        "review_rate": 4.9,
        "customer_name": "Melanie Lopez",
        "service_type": "Garage Door Insulation",
        "tech_name": "Michael J.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-03-08",
        "review_rate": 4.8,
        "customer_name": "Mary Miller",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Jared L.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-02-12",
        "review_rate": 5.0,
        "customer_name": "Sean Cox",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Randall W.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-12-12",
        "review_rate": 4.9,
        "customer_name": "Karen Clark",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Maxwell W.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-12-29",
        "review_rate": 4.9,
        "customer_name": "Matthew Brooks",
        "service_type": "Garage Door Repair",
        "tech_name": "Lisa P.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-11-15",
        "review_rate": 4.9,
        "customer_name": "Brooke Solomon",
        "service_type": "Garage Door Opener",
        "tech_name": "Daniel B.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-10-18",
        "review_rate": 4.8,
        "customer_name": "Richard Collins",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Faith J.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-10-18",
        "review_rate": 4.9,
        "customer_name": "Mary King",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Richard C.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-05-13",
        "review_rate": 4.8,
        "customer_name": "Alexis Jones",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Alexandra M.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-03-19",
        "review_rate": 4.8,
        "customer_name": "Samantha Nelson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Julie R.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-03-25",
        "review_rate": 5.0,
        "customer_name": "Monique Thompson",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Diana S.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-05-01",
        "review_rate": 4.9,
        "customer_name": "John Hunt",
        "service_type": "Garage Door Services",
        "tech_name": "Kim C.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-10-10",
        "review_rate": 4.9,
        "customer_name": "Mary Adams",
        "service_type": "Garage Door Services",
        "tech_name": "Walter R.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-10-22",
        "review_rate": 4.9,
        "customer_name": "Janet Irwin",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Eric J.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-07-22",
        "review_rate": 5.0,
        "customer_name": "Melissa Rivera",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "James M.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-07-04",
        "review_rate": 5.0,
        "customer_name": "Wendy Meyer",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Elizabeth A.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-12-26",
        "review_rate": 4.9,
        "customer_name": "Taylor Donaldson",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Timothy G.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-07-09",
        "review_rate": 4.9,
        "customer_name": "Mrs. Melissa Adams MD",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Erika V.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-03-22",
        "review_rate": 4.9,
        "customer_name": "Shannon Williams",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Connie P.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-09-01",
        "review_rate": 4.9,
        "customer_name": "Michael Green",
        "service_type": "Garage Door Installation",
        "tech_name": "Joy H.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-10-29",
        "review_rate": 4.9,
        "customer_name": "Jason Maxwell",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Michelle L.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-07-12",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Douglas",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "George G.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-02-11",
        "review_rate": 5.0,
        "customer_name": "Gina Graves",
        "service_type": "Garage Door Installation",
        "tech_name": "Jennifer M.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-12-09",
        "review_rate": 4.9,
        "customer_name": "Brent Carney",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Andrea S.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-07-18",
        "review_rate": 4.9,
        "customer_name": "Brenda White",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Jeffery J.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-01-08",
        "review_rate": 4.8,
        "customer_name": "Dylan Thompson",
        "service_type": "Garage Door Opener",
        "tech_name": "Raymond C.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-12-11",
        "review_rate": 4.8,
        "customer_name": "Kyle Parker",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Michael W.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-05-17",
        "review_rate": 4.9,
        "customer_name": "Craig Huber",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Katherine B.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-04-13",
        "review_rate": 5.0,
        "customer_name": "Francisco Fitzgerald",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "David J.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-07-16",
        "review_rate": 5.0,
        "customer_name": "Miranda Davis",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Megan C.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-08-20",
        "review_rate": 4.9,
        "customer_name": "Frederick Tyler",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Sarah B.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-01-31",
        "review_rate": 5.0,
        "customer_name": "Ronald Jensen",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Trevor E.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-06-26",
        "review_rate": 4.9,
        "customer_name": "Lori Flores",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Ivan B.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-08-27",
        "review_rate": 4.8,
        "customer_name": "Taylor Morgan",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Danielle H.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-04-25",
        "review_rate": 5.0,
        "customer_name": "Robert Davis",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Preston L.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-10-17",
        "review_rate": 4.9,
        "customer_name": "Cynthia Oneill",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Michael M.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-03-07",
        "review_rate": 4.9,
        "customer_name": "Alexandria Long",
        "service_type": "Garage Door Services",
        "tech_name": "Bonnie P.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-06-03",
        "review_rate": 5.0,
        "customer_name": "Andrew Hunter",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Emily H.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-01-04",
        "review_rate": 4.8,
        "customer_name": "Sonya Miller",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Daniel R.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-07-28",
        "review_rate": 4.9,
        "customer_name": "Daniel Davis",
        "service_type": "Garage Door Off Track",
        "tech_name": "Jodi H.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-03-10",
        "review_rate": 5.0,
        "customer_name": "Carlos Gordon",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Melody B.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-02-07",
        "review_rate": 5.0,
        "customer_name": "Dustin Perez",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Kendra W.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-05-12",
        "review_rate": 4.9,
        "customer_name": "Emily Sanchez",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Logan O.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-06-25",
        "review_rate": 5.0,
        "customer_name": "Sarah Cook",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Kimberly C.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-04-08",
        "review_rate": 4.8,
        "customer_name": "Shannon Blake",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Steven S.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-05-15",
        "review_rate": 5.0,
        "customer_name": "Jennifer Gomez",
        "service_type": "Garage Door Services",
        "tech_name": "Angela M.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-09-22",
        "review_rate": 4.9,
        "customer_name": "Brian Lopez",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Michele W.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-08-14",
        "review_rate": 5.0,
        "customer_name": "William Wallace",
        "service_type": "Garage Door Opener",
        "tech_name": "Michelle L.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-05-18",
        "review_rate": 4.9,
        "customer_name": "Rick Cole",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Mark B.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-01-21",
        "review_rate": 4.9,
        "customer_name": "Jesse Williamson",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Jeffrey R.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-02-09",
        "review_rate": 4.8,
        "customer_name": "Joseph Burns",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Matthew R.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-12-01",
        "review_rate": 5.0,
        "customer_name": "Jeff Powell",
        "service_type": "Garage Door Services",
        "tech_name": "Jeanne S.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-07-06",
        "review_rate": 5.0,
        "customer_name": "John Jensen",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Eric S.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-12-29",
        "review_rate": 4.9,
        "customer_name": "Brianna Russell",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Erin R.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-10-06",
        "review_rate": 4.8,
        "customer_name": "Kimberly Johnson",
        "service_type": "Garage Door Installation",
        "tech_name": "Anna B.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-07-28",
        "review_rate": 5.0,
        "customer_name": "Larry Davis",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Andrea G.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-22",
        "review_rate": 4.9,
        "customer_name": "James Miller",
        "service_type": "Garage Door Insulation",
        "tech_name": "Kevin M.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-07",
        "review_rate": 5.0,
        "customer_name": "David Yang",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Joshua C.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-01-22",
        "review_rate": 4.8,
        "customer_name": "Justin Ross",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Samantha U.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-01-03",
        "review_rate": 5.0,
        "customer_name": "Travis Dickson",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Tracy J.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-01-14",
        "review_rate": 4.9,
        "customer_name": "Heather Wheeler",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Kimberly B.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-07-10",
        "review_rate": 4.9,
        "customer_name": "Stephanie Howard",
        "service_type": "Garage Door Services",
        "tech_name": "Amanda S.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-23",
        "review_rate": 4.8,
        "customer_name": "Destiny Frank",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Mary L.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-04-08",
        "review_rate": 4.9,
        "customer_name": "Courtney Carroll",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Martin W.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-06-03",
        "review_rate": 4.8,
        "customer_name": "Deborah Francis",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Jennifer J.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-09-24",
        "review_rate": 4.9,
        "customer_name": "Louis Nelson",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Bailey H.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-02-19",
        "review_rate": 4.9,
        "customer_name": "Joel George",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Matthew G.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-09-18",
        "review_rate": 4.8,
        "customer_name": "Timothy Young",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Erin P.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-11-26",
        "review_rate": 4.8,
        "customer_name": "Misty Valdez",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Katherine I.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-04-18",
        "review_rate": 4.9,
        "customer_name": "Casey Roberts",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "William W.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-15",
        "review_rate": 4.9,
        "customer_name": "Calvin Johnson",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Destiny F.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-17",
        "review_rate": 4.9,
        "customer_name": "Megan Smith",
        "service_type": "Garage Door Off Track",
        "tech_name": "Janice P.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-05-31",
        "review_rate": 4.9,
        "customer_name": "David Kemp",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Nicholas D.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-05-24",
        "review_rate": 4.8,
        "customer_name": "Clarence Cook",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Eric D.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-07-15",
        "review_rate": 4.8,
        "customer_name": "Christopher Martin",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Darrell P.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-08-02",
        "review_rate": 4.9,
        "customer_name": "Stephen Lambert",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "David F.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-02",
        "review_rate": 5.0,
        "customer_name": "Keith Johnson",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "William D.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-26",
        "review_rate": 4.9,
        "customer_name": "Christine Greer",
        "service_type": "Garage Door Services",
        "tech_name": "John S.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-11-19",
        "review_rate": 4.9,
        "customer_name": "Austin Powell",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Christopher M.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-01-22",
        "review_rate": 5.0,
        "customer_name": "Donna Jones",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Christina S.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-02-29",
        "review_rate": 4.9,
        "customer_name": "William Hinton",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Brenda O.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-02-20",
        "review_rate": 4.8,
        "customer_name": "Howard Johnson",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Teresa H.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-06-17",
        "review_rate": 4.8,
        "customer_name": "Autumn Richmond",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Brooke W.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-08-11",
        "review_rate": 4.9,
        "customer_name": "Sophia Murphy",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Melvin S.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-04-29",
        "review_rate": 5.0,
        "customer_name": "Kenneth Miles",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Michael N.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-06-16",
        "review_rate": 5.0,
        "customer_name": "Russell Huang",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Gabrielle C.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-05-06",
        "review_rate": 4.9,
        "customer_name": "Susan Bauer",
        "service_type": "Garage Door Installation",
        "tech_name": "Nicole W.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-25",
        "review_rate": 4.9,
        "customer_name": "Johnny Wilson",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "John H.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-02-21",
        "review_rate": 5.0,
        "customer_name": "Rebecca Johnson",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Amber M.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-03-16",
        "review_rate": 4.9,
        "customer_name": "Patricia Smith",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Michelle J.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-02-06",
        "review_rate": 4.8,
        "customer_name": "Joe Mccullough",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Frank L.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-08-28",
        "review_rate": 4.8,
        "customer_name": "Alexandria Palmer",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Brian N.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-05-10",
        "review_rate": 4.9,
        "customer_name": "Dominique Briggs",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Nancy S.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-01-03",
        "review_rate": 4.8,
        "customer_name": "Kaitlyn Clarke",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Alicia B.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-05-21",
        "review_rate": 4.9,
        "customer_name": "Keith Lamb",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Adam W.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-21",
        "review_rate": 4.9,
        "customer_name": "Cory Cox",
        "service_type": "Garage Door Insulation",
        "tech_name": "Richard W.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-05-06",
        "review_rate": 4.9,
        "customer_name": "Tiffany Cantrell",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Molly D.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-07-04",
        "review_rate": 4.9,
        "customer_name": "Toni Gutierrez",
        "service_type": "Garage Door Installation",
        "tech_name": "Kayla C.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-03-20",
        "review_rate": 4.8,
        "customer_name": "Patrick Parker",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Joseph C.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-04-09",
        "review_rate": 4.9,
        "customer_name": "Lori Robinson",
        "service_type": "Garage Door Installation",
        "tech_name": "Tiffany B.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-08-09",
        "review_rate": 4.9,
        "customer_name": "Katherine Newman",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Brenda H.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-05-26",
        "review_rate": 4.9,
        "customer_name": "Suzanne Phillips",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Michelle S.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-10",
        "review_rate": 4.9,
        "customer_name": "Steven Villa",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Nathan P.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-04-12",
        "review_rate": 4.8,
        "customer_name": "Austin Baker",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Tina J.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-08",
        "review_rate": 4.8,
        "customer_name": "Jesus Mullen",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Michael M.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-03-31",
        "review_rate": 5.0,
        "customer_name": "Joseph Phillips",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Robert D.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-07-22",
        "review_rate": 4.9,
        "customer_name": "Ryan Scott",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Crystal L.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-04-10",
        "review_rate": 4.8,
        "customer_name": "Bernard Kidd",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Destiny J.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-08-03",
        "review_rate": 4.8,
        "customer_name": "Michael Perez",
        "service_type": "Garage Door Insulation",
        "tech_name": "Shane J.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-08-26",
        "review_rate": 4.9,
        "customer_name": "Mary Bryant",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Hannah S.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-12-26",
        "review_rate": 5.0,
        "customer_name": "Olivia Mitchell",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Melanie T.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-08-18",
        "review_rate": 4.9,
        "customer_name": "Tyrone Lopez",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jessica L.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-04-18",
        "review_rate": 4.9,
        "customer_name": "Antonio Morgan",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Jennifer C.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-09-03",
        "review_rate": 4.9,
        "customer_name": "Michael Eaton",
        "service_type": "Garage Door Services",
        "tech_name": "Margaret R.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-01-07",
        "review_rate": 4.9,
        "customer_name": "Casey Turner",
        "service_type": "Custom Garage Door Design",
        "tech_name": "James C.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-02-11",
        "review_rate": 4.9,
        "customer_name": "Angelica King",
        "service_type": "Garage Door Opener",
        "tech_name": "Kimberly J.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-12-18",
        "review_rate": 4.8,
        "customer_name": "Barbara Bush",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Justin G.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-05-07",
        "review_rate": 4.8,
        "customer_name": "Kristie Jackson",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Jasmine W.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-11-14",
        "review_rate": 4.8,
        "customer_name": "Daniel Mejia",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Jason L.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-01-22",
        "review_rate": 4.8,
        "customer_name": "Julie Moore",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Ralph S.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-02-20",
        "review_rate": 4.8,
        "customer_name": "Annette Hancock",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Brianna J.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-05-19",
        "review_rate": 4.9,
        "customer_name": "Michael Clark",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Eric M.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-07-02",
        "review_rate": 5.0,
        "customer_name": "Arthur Nguyen",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Nichole T.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-05-12",
        "review_rate": 4.9,
        "customer_name": "Dana Mcmahon",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "William M.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-01-03",
        "review_rate": 4.9,
        "customer_name": "Mary Johnson",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Cheryl M.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-04-05",
        "review_rate": 5.0,
        "customer_name": "Dr. Adam Morrison",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "James L.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-12-24",
        "review_rate": 5.0,
        "customer_name": "Melissa Carter",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Dennis J.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-04-25",
        "review_rate": 4.8,
        "customer_name": "Randy Gordon",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Jonathon M.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-02-25",
        "review_rate": 4.9,
        "customer_name": "Heidi Duffy",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Angela K.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-12-16",
        "review_rate": 5.0,
        "customer_name": "David Bernard",
        "service_type": "Garage Door Installation",
        "tech_name": "Emily S.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-03-10",
        "review_rate": 5.0,
        "customer_name": "Sarah Ramirez",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Christopher P.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-03-12",
        "review_rate": 5.0,
        "customer_name": "Joshua Perez",
        "service_type": "Garage Door Repair",
        "tech_name": "Eric S.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-07-05",
        "review_rate": 4.9,
        "customer_name": "Carol Vasquez",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Jillian C.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-05-25",
        "review_rate": 4.9,
        "customer_name": "Christopher Robertson",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Christopher F.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-06-13",
        "review_rate": 4.9,
        "customer_name": "Stephen Guzman",
        "service_type": "Garage Door Installation",
        "tech_name": "Nicholas M.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-06-24",
        "review_rate": 5.0,
        "customer_name": "Christopher Baker",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Antonio K.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-08-10",
        "review_rate": 4.9,
        "customer_name": "Michael Meyer",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Stephanie H.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-11-13",
        "review_rate": 4.8,
        "customer_name": "Paul Oconnor",
        "service_type": "Garage Door Services",
        "tech_name": "Lori G.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-07-29",
        "review_rate": 4.9,
        "customer_name": "Kelly Macdonald",
        "service_type": "Garage Door Opener",
        "tech_name": "Edward S.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-04-17",
        "review_rate": 4.8,
        "customer_name": "Tyler King",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Scott A.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-06-27",
        "review_rate": 5.0,
        "customer_name": "Robert Pratt",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Mike T.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-12-10",
        "review_rate": 4.8,
        "customer_name": "Parker Barnett",
        "service_type": "Garage Door Off Track",
        "tech_name": "Sandra N.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-10-02",
        "review_rate": 4.8,
        "customer_name": "Rebecca Hamilton",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Matthew M.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-09-02",
        "review_rate": 4.9,
        "customer_name": "Jesus Williams",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "David P.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-03-31",
        "review_rate": 4.9,
        "customer_name": "Samuel Sandoval",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Erin B.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-07-30",
        "review_rate": 4.9,
        "customer_name": "John Edwards",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Zachary M.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-12-01",
        "review_rate": 4.9,
        "customer_name": "Michael Thomas",
        "service_type": "Garage Door Opener",
        "tech_name": "Timothy T.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-12-13",
        "review_rate": 4.8,
        "customer_name": "Amy Adams",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Kevin B.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-03-24",
        "review_rate": 4.9,
        "customer_name": "Joyce Jenkins",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Jacqueline A.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-02-16",
        "review_rate": 5.0,
        "customer_name": "Mr. Michael Baker DDS",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Jeremy E.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-07-14",
        "review_rate": 4.9,
        "customer_name": "Christopher Prince",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Sarah S.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-02-25",
        "review_rate": 4.9,
        "customer_name": "John Tucker",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Donna S.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-03-07",
        "review_rate": 4.9,
        "customer_name": "Cassandra Willis",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Allison M.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-05-29",
        "review_rate": 4.8,
        "customer_name": "Ashley Bates",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Tanya L.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-08-25",
        "review_rate": 5.0,
        "customer_name": "Jay Hancock",
        "service_type": "Garage Door Services",
        "tech_name": "Sandra H.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-02",
        "review_rate": 4.8,
        "customer_name": "Katie Carpenter",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Maria R.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-13",
        "review_rate": 4.9,
        "customer_name": "Warren Fisher",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Mark D.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-02-15",
        "review_rate": 4.9,
        "customer_name": "Randy Fisher MD",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Scott S.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-10",
        "review_rate": 4.9,
        "customer_name": "Christopher Thompson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Dillon T.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-12-19",
        "review_rate": 4.9,
        "customer_name": "William Davidson DDS",
        "service_type": "Garage Door Off Track",
        "tech_name": "Brian H.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-02-24",
        "review_rate": 4.9,
        "customer_name": "Jacqueline Fisher",
        "service_type": "Garage Door Opener",
        "tech_name": "Andrew M.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-02-25",
        "review_rate": 4.9,
        "customer_name": "Stephanie Parker",
        "service_type": "Garage Door Installation",
        "tech_name": "Danielle M.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-11-03",
        "review_rate": 5.0,
        "customer_name": "Gabrielle Martin",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "James B.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-01-23",
        "review_rate": 4.8,
        "customer_name": "Travis Thomas",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Lauren H.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-05-09",
        "review_rate": 4.9,
        "customer_name": "Justin Jefferson",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Ashley M.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-26",
        "review_rate": 4.9,
        "customer_name": "Emily Weber",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Donald A.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-12-06",
        "review_rate": 4.8,
        "customer_name": "Donna Barnes",
        "service_type": "Garage Door Services",
        "tech_name": "Kara S.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-08-07",
        "review_rate": 4.9,
        "customer_name": "Michele Cross",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Kimberly R.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-05-06",
        "review_rate": 5.0,
        "customer_name": "Danielle Grant",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Emily W.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-02",
        "review_rate": 4.9,
        "customer_name": "Jonathan Espinoza",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Barbara L.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-04-11",
        "review_rate": 4.8,
        "customer_name": "Dalton Jones",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "James M.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-19",
        "review_rate": 4.8,
        "customer_name": "Michael Brown",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "David L.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-02-08",
        "review_rate": 5.0,
        "customer_name": "Stacey Hernandez",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Cody F.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-02-21",
        "review_rate": 5.0,
        "customer_name": "Brandon Kemp",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Tina L.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-11-19",
        "review_rate": 4.9,
        "customer_name": "Kelsey Jensen",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Donna D.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-10",
        "review_rate": 4.9,
        "customer_name": "Anthony Butler",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "John R.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-02-04",
        "review_rate": 4.9,
        "customer_name": "Kathleen Davis",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Kathryn E.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-04-02",
        "review_rate": 5.0,
        "customer_name": "Colleen Mueller",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Gina B.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-05-01",
        "review_rate": 4.9,
        "customer_name": "Kelly Ramirez",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Angela B.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-06-18",
        "review_rate": 4.9,
        "customer_name": "Amanda Martinez",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Veronica S.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-06-26",
        "review_rate": 4.9,
        "customer_name": "Deborah Edwards",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Nicholas N.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-01-29",
        "review_rate": 4.9,
        "customer_name": "Kathleen Myers",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Karen B.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-01-27",
        "review_rate": 4.9,
        "customer_name": "Stacy Martinez",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Linda A.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-11-01",
        "review_rate": 4.8,
        "customer_name": "Lawrence Moreno",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Wanda W.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-09-01",
        "review_rate": 5.0,
        "customer_name": "Beverly Herrera",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Kristin J.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-01-13",
        "review_rate": 4.9,
        "customer_name": "Colin Flynn",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Matthew B.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-06-07",
        "review_rate": 4.8,
        "customer_name": "Hailey Davis",
        "service_type": "Garage Door Off Track",
        "tech_name": "Tyrone W.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-07-15",
        "review_rate": 5.0,
        "customer_name": "Stephanie Richards",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Wanda G.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-01-13",
        "review_rate": 4.9,
        "customer_name": "David Campbell",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Adam G.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-24",
        "review_rate": 5.0,
        "customer_name": "Daniel Bailey",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Steven H.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-04-11",
        "review_rate": 4.9,
        "customer_name": "Amanda Fletcher",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Michael B.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-07-28",
        "review_rate": 4.9,
        "customer_name": "Faith Roberts",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Brad W.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-08-07",
        "review_rate": 5.0,
        "customer_name": "John Brooks",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Richard S.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-07-13",
        "review_rate": 5.0,
        "customer_name": "Derek Hoffman",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Whitney W.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-02",
        "review_rate": 4.9,
        "customer_name": "Joseph Decker",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Phillip M.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-11-09",
        "review_rate": 4.8,
        "customer_name": "Pamela York",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Wayne W.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-11-03",
        "review_rate": 5.0,
        "customer_name": "Brandy Serrano",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Crystal C.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-07-08",
        "review_rate": 4.9,
        "customer_name": "Jennifer Jenkins",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Karen C.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-08-28",
        "review_rate": 4.9,
        "customer_name": "Lauren Manning",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Mark H.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-04-08",
        "review_rate": 4.8,
        "customer_name": "Christine Walker",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "James D.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-03",
        "review_rate": 4.9,
        "customer_name": "Sonya Holloway",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Matthew W.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-03",
        "review_rate": 4.9,
        "customer_name": "Bill Nixon",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Ricky M.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-12-29",
        "review_rate": 5.0,
        "customer_name": "Sarah Murillo",
        "service_type": "Garage Door Repair",
        "tech_name": "Michael W.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-05-31",
        "review_rate": 4.8,
        "customer_name": "Shane Hunter",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Steven D.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-11-14",
        "review_rate": 4.8,
        "customer_name": "Grant Bishop",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Katrina L.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-03-08",
        "review_rate": 5.0,
        "customer_name": "Matthew Wilson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Walter B.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-12-05",
        "review_rate": 4.9,
        "customer_name": "Betty Miller",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Brandy P.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-10-26",
        "review_rate": 5.0,
        "customer_name": "Cindy Henry",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Justin H.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-12-16",
        "review_rate": 4.9,
        "customer_name": "Nicholas Avila",
        "service_type": "Garage Door Off Track",
        "tech_name": "Justin B.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-06-16",
        "review_rate": 4.8,
        "customer_name": "Juan Brooks",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Joshua O.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-05-22",
        "review_rate": 4.8,
        "customer_name": "Eddie Miller",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Jeffrey F.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-02-21",
        "review_rate": 4.9,
        "customer_name": "Eric Matthews",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Valerie V.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-11-12",
        "review_rate": 4.9,
        "customer_name": "Brian Park",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Randy T.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-02-19",
        "review_rate": 4.9,
        "customer_name": "Anthony Marshall",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Cheryl A.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-03-15",
        "review_rate": 4.8,
        "customer_name": "Michael Thompson",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Timothy P.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-04-17",
        "review_rate": 5.0,
        "customer_name": "Christopher Harris",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Lisa H.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-12-20",
        "review_rate": 5.0,
        "customer_name": "Brianna Jackson",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Bryan S.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-12-19",
        "review_rate": 4.9,
        "customer_name": "Rachel Mathews",
        "service_type": "Garage Door Off Track",
        "tech_name": "Kristina H.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-06-16",
        "review_rate": 4.9,
        "customer_name": "Dustin Walsh",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Peter L.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-03-01",
        "review_rate": 4.9,
        "customer_name": "Tracy Davis",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Matthew S.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-11-17",
        "review_rate": 4.9,
        "customer_name": "Catherine Shepard",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Lori R.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-10-19",
        "review_rate": 4.9,
        "customer_name": "Richard Howard",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Jessica B.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-05-21",
        "review_rate": 4.9,
        "customer_name": "Stephanie Price",
        "service_type": "Garage Door Installation",
        "tech_name": "Stephanie H.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-03-31",
        "review_rate": 4.9,
        "customer_name": "Kenneth Williams",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Evelyn S.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-04-27",
        "review_rate": 5.0,
        "customer_name": "Jermaine King",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Kayla P.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-01-20",
        "review_rate": 4.8,
        "customer_name": "William Dyer",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Brianna S.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-03-15",
        "review_rate": 4.9,
        "customer_name": "Ryan Stevenson",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "James W.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-09-05",
        "review_rate": 4.9,
        "customer_name": "Olivia Brock",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Ross H.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-01-31",
        "review_rate": 5.0,
        "customer_name": "David Rogers",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Riley S.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-02-21",
        "review_rate": 4.9,
        "customer_name": "Matthew Swanson",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Christopher N.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-12-23",
        "review_rate": 4.9,
        "customer_name": "Susan Mckay",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Michael S.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-02-01",
        "review_rate": 4.9,
        "customer_name": "Gary Caldwell",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Katie L.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-08-25",
        "review_rate": 4.8,
        "customer_name": "Rachel Stevens",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Marcus B.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-11-08",
        "review_rate": 5.0,
        "customer_name": "Brian Noble",
        "service_type": "Garage Door Services",
        "tech_name": "Francisco J.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-06-26",
        "review_rate": 5.0,
        "customer_name": "Vanessa Greer",
        "service_type": "Garage Door Off Track",
        "tech_name": "Cody L.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-05-01",
        "review_rate": 4.9,
        "customer_name": "Hector Marshall",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Laura H.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-11-05",
        "review_rate": 4.9,
        "customer_name": "Steven Taylor",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Jon D.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-10-11",
        "review_rate": 4.8,
        "customer_name": "Kenneth Brady",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Sandra F.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-09-26",
        "review_rate": 4.9,
        "customer_name": "Mr. Aaron Newton II",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Michael T.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-10-27",
        "review_rate": 4.9,
        "customer_name": "Dawn Adams",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Kevin S.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-03-29",
        "review_rate": 4.9,
        "customer_name": "Valerie Hughes",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Brianna C.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-01-09",
        "review_rate": 4.9,
        "customer_name": "Justin Mccarthy",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Kristina R.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-11-26",
        "review_rate": 4.9,
        "customer_name": "Jay Robbins",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Johnny S.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-04-03",
        "review_rate": 4.8,
        "customer_name": "Phillip Fernandez",
        "service_type": "Garage Door Services",
        "tech_name": "Joseph H.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-07-25",
        "review_rate": 4.9,
        "customer_name": "Stephanie Woods",
        "service_type": "Garage Door Opener",
        "tech_name": "Pamela B.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-01-01",
        "review_rate": 5.0,
        "customer_name": "Robert Smith",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Michele B.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-07-30",
        "review_rate": 4.9,
        "customer_name": "Alicia Harrison",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Darrell J.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-03-21",
        "review_rate": 5.0,
        "customer_name": "Amanda Martin",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Jessica F.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-08-25",
        "review_rate": 4.9,
        "customer_name": "Steve Carroll",
        "service_type": "Garage Door Repair",
        "tech_name": "Charlene B.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-12-08",
        "review_rate": 4.8,
        "customer_name": "Hannah Campbell",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Andrew W.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-10-09",
        "review_rate": 4.9,
        "customer_name": "Robert Garcia",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Diane C.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-03-06",
        "review_rate": 4.9,
        "customer_name": "Mrs. Kristy Smith",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "James A.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-03-05",
        "review_rate": 4.9,
        "customer_name": "James Woods",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Matthew P.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-08-25",
        "review_rate": 4.8,
        "customer_name": "Joan Villanueva",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Kyle Y.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-05-02",
        "review_rate": 4.9,
        "customer_name": "Scott Bryan",
        "service_type": "Garage Door Opener",
        "tech_name": "Miranda L.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-09-23",
        "review_rate": 4.8,
        "customer_name": "Daniel Campbell",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Tonya S.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-12-25",
        "review_rate": 4.8,
        "customer_name": "Jessica Patterson",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Emma F.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-05-26",
        "review_rate": 4.9,
        "customer_name": "Shane Ortiz",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Andrew J.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-10-14",
        "review_rate": 4.9,
        "customer_name": "Rebecca Hernandez",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Paul T.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-03-31",
        "review_rate": 4.9,
        "customer_name": "Joyce Brown",
        "service_type": "Garage Door Off Track",
        "tech_name": "Tanya N.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-09-15",
        "review_rate": 5.0,
        "customer_name": "Bianca Trujillo",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Patrick R.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-07-18",
        "review_rate": 4.9,
        "customer_name": "Laura Sullivan",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Donna H.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-12-26",
        "review_rate": 5.0,
        "customer_name": "Robert Larsen",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Nathan P.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-08-12",
        "review_rate": 4.9,
        "customer_name": "Joseph Berry",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Joseph F.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-02-16",
        "review_rate": 4.8,
        "customer_name": "Anthony Brewer",
        "service_type": "Garage Door Services",
        "tech_name": "Cynthia C.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-05-14",
        "review_rate": 4.9,
        "customer_name": "Jason Morgan",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Wanda D.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-10-24",
        "review_rate": 4.8,
        "customer_name": "Craig Miller",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Victoria E.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-05",
        "review_rate": 4.8,
        "customer_name": "Jose Guerrero",
        "service_type": "Garage Door Insulation",
        "tech_name": "Jared S.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-03-05",
        "review_rate": 4.9,
        "customer_name": "Dennis Miller",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Stacey R.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-12-10",
        "review_rate": 4.9,
        "customer_name": "Kimberly Mason",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Bradley M.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-07-26",
        "review_rate": 5.0,
        "customer_name": "Isaiah Arias",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Scott B.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-08-15",
        "review_rate": 4.9,
        "customer_name": "Lisa Warner DDS",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Brian S.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-05-20",
        "review_rate": 4.8,
        "customer_name": "David Parrish",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "John C.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-11-19",
        "review_rate": 4.9,
        "customer_name": "Craig Conway",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Destiny B.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-10-06",
        "review_rate": 4.8,
        "customer_name": "Danielle Blevins",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Luis P.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-10-13",
        "review_rate": 4.9,
        "customer_name": "Sandra Morgan",
        "service_type": "Garage Door Installation",
        "tech_name": "Leah Z.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-07-29",
        "review_rate": 4.9,
        "customer_name": "Philip Robinson",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Alexandra S.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-01-24",
        "review_rate": 4.9,
        "customer_name": "Carol Jackson",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Samuel M.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-13",
        "review_rate": 4.8,
        "customer_name": "Lauren Black",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Cindy S.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-21",
        "review_rate": 4.8,
        "customer_name": "Angela Ramirez",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Tina G.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-02-12",
        "review_rate": 4.9,
        "customer_name": "Matthew Williams",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Maria I.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-09-20",
        "review_rate": 4.9,
        "customer_name": "James Stewart",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Jonathan P.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-03-15",
        "review_rate": 4.9,
        "customer_name": "Kathleen Barrett",
        "service_type": "Garage Door Insulation",
        "tech_name": "Mark W.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-07-21",
        "review_rate": 4.9,
        "customer_name": "Leroy Howard",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Brian W.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-09-29",
        "review_rate": 4.9,
        "customer_name": "Richard Rose",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Darrell C.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-11-19",
        "review_rate": 5.0,
        "customer_name": "Veronica Reed",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Scott S.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-03-03",
        "review_rate": 4.9,
        "customer_name": "Debbie Jarvis",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Stephanie L.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-02-14",
        "review_rate": 4.9,
        "customer_name": "Heather Butler",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Michael Q.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-05-24",
        "review_rate": 5.0,
        "customer_name": "William Jones",
        "service_type": "Garage Door Insulation",
        "tech_name": "James C.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-11",
        "review_rate": 4.9,
        "customer_name": "Brenda King",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Mitchell F.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-12-26",
        "review_rate": 4.8,
        "customer_name": "Dr. Christopher Smith",
        "service_type": "Garage Door Off Track",
        "tech_name": "Kelly C.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-10-27",
        "review_rate": 4.9,
        "customer_name": "David Gilbert",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Jason J.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-02-04",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Carter",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Amber A.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-12-17",
        "review_rate": 5.0,
        "customer_name": "Margaret Johnson",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Sarah J.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-11-03",
        "review_rate": 4.9,
        "customer_name": "Courtney Jones",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Robert W.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-02",
        "review_rate": 5.0,
        "customer_name": "Tyler Davenport",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Brandon C.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-01-18",
        "review_rate": 4.9,
        "customer_name": "Russell Johns",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Shannon J.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-07-24",
        "review_rate": 4.9,
        "customer_name": "Dr. Susan Huang",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Heather C.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-11-18",
        "review_rate": 4.9,
        "customer_name": "James Maynard",
        "service_type": "Garage Door Installation",
        "tech_name": "Ashley L.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-06-19",
        "review_rate": 5.0,
        "customer_name": "Corey Stanley",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Karen M.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-08-30",
        "review_rate": 4.8,
        "customer_name": "Scott Stark",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Christina A.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-11-09",
        "review_rate": 5.0,
        "customer_name": "Sabrina Reyes",
        "service_type": "Garage Door Opener",
        "tech_name": "Alisha C.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-09-08",
        "review_rate": 4.8,
        "customer_name": "Ryan Morgan",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Timothy J.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-05-06",
        "review_rate": 4.8,
        "customer_name": "Jon Brown",
        "service_type": "Garage Door Repair",
        "tech_name": "Matthew M.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    }
]
export default LocationReviewsData;
